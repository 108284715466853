import React, { useState } from "react";

const PrivacyBody = () => {
const [present, setPresent] = useState(1)
const sections = [{id:1,title:'Collection of Information',dir:'coi'},{id:2,title:'Use of Your Information',dir:'uoyi'}
,{id:3,title:'Disclaimer',dir:'disclaimer'},
{id:4,title:'Indemnity',dir:'indemnity'},{id:5,title:'Termination and Remedies',dir:'tar'}
,{id:6,title:'Miscellaneous',dir:'mis'},{id:7,title:'Governing Law and Forum',dir:'glaf'},{id:8,title:'Effective date',dir:'ed'}]
const displaySection = (id,dir) => {
    setPresent(id)
    document.getElementById(dir).scrollIntoView({
        behavior: 'smooth'
      })
}
   return ( 
        <div className="px-8 lg:p-10 lg:mx-10 grid grid-cols-8 gap-x-8 mb-10">
            <div className="hidden lg:flex col-span-2 flex flex-col space-y-3 text-[18px] font-[400] mt-20 pt-4">
                { sections.map(({id,title,dir},ind) => {
                    return (
                        <span onClick={()=>displaySection(id,dir)} key={ind} className={`cursor-pointer ${present == id ? 'text-[#E1693E]' : 'text-[#333333]'}`}>{title}</span>
                    )
                }) }
            </div>
            <div className="col-span-8 lg:col-span-6 flex flex-col space-y-4 text-[18px] text-[#4F4F4F] mt-7 lg:mt-auto">
                <div className="flex flex-col space-y-4">
                    <span className="text-black font-[700] text-[30px] lg:text-[48px]">Privacy Policy </span>
                    <span className="text-[#333333] font-[400] text-[18px]">Welcome to the Pora Privacy Policy (“Policy”)! This explains how we collect, store, protect, and share your information, and with whom we share it.</span>
                </div>
                <div id="coi" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Collection of Information</span>
                    <span className="text-[20px] font-[600] pt-2">Registration Information</span>
                    <span>When you download the App and create an account (“Account”), we may collect certain information (“Registration Information”) about you, such as:</span>
                    <span className="pl-6">
                        <ul className="list-disc space-y-3">
                            <li>Name;</li>
                            <li>Username;</li>
                            <li>Email address;</li>
                            <li>Mobile number;</li>
                            <li>Gender identity;</li>
                            <li>Date of birth;</li>
                            <li>Photographs;</li>
                            <li>Location; and</li>
                            <li>Login information for social media accounts that you connect to your Pora Account. It is your responsibility to ensure that your account details are kept up to date. If your phone number changes, please ensure that you update this in your account.</li>
                        </ul>
                    </span>
                    <span className="pt-3">The information we collect helps to enhance the App and verify our Users. Registration Information such as name and username may be visible to other Users who view your profile page. Other information that we may collect include:</span>
                    <span className="pl-6">
                        <ul className="list-decimal space-y-3">
                            <li>Identifiers, such as name and location;</li>
                            <li>Personal information, such as contact (including email and telephone number) and financial information;</li>
                            <li>Characteristics which are protected grounds under the Ontario Human Rights Code or equivalent legislation (if you choose to provide them), such as age, gender identity, marital status, sexual orientation, race, ancestry, national origin, religion, and medical conditions;</li>
                            <li>Commercial information, such as transaction information and purchase history;</li>
                            <li>Internet or network activity information, such as browsing history and interactions with our Sites and App;</li>
                            <li>Geolocation data, such as mobile device location;</li>
                            <li>Audio, electronic, visual and similar information, such as photos and videos;</li>
                            <li>Professional or employment-related information, such as work history and prior employer;</li>
                            <li>Non-public education information; and</li>
                            <li>Inferences drawn from any of the personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics.</li>
                        </ul>
                    </span>
                    <span className="text-[20px] font-[600] pt-5">Profile Information</span>
                    <span>We recommend and encourage you (and all our members) to think carefully about the information you disclose about yourself. We do not recommend that you put email addresses, URLs, instant messaging details, phone numbers, full names or addresses, credit card details, national identity numbers, drivers’ licence details and other sensitive information which is open to abuse and misuse on your profile.</span>
                    <span>When you post information about yourself or use the messaging function to communicate with other Users, the amount of personal information you share is at your own risk.</span>
                </div>
                <div id="uoyi" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Use of Your Information</span>
                    <span>Our main goal is to ensure that your experience on Pora is an enjoyable one. In order to deliver an enjoyable experience to you, we may use your Registration and other information to:</span>
                    <span className="pl-6">
                        <ul className="list-disc space-y-3">
                            <li>Name;</li>
                            <li>Username;</li>
                            <li>Email address;</li>
                            <li>Mobile number;</li>
                            <li>Gender identity;</li>
                            <li>Date of birth;</li>
                            <li>Photographs;</li>
                            <li>Location; and</li>
                            <li>Login information for social media accounts that you connect to your Pora Account. It is your responsibility to ensure that your account details are kept up to date. If your phone number changes, please ensure that you update this in your account.</li>
                        </ul>
                    </span>
                    <span className="text-[20px] font-[600] pt-5">Moderation Practices</span>
                    <span>We use a combination of automated systems and a team of administrators to monitor and review accounts (including photos and any other information uploaded 
                        onto user profiles) and messages for content that indicates breaches of our Terms and Conditions of Use. 
                        If an account or message meets certain criteria that demonstrate that the Terms and Conditions of Use are likely to have been breached, the relevant account 
                        will be subject to a warning and the user’s access restricted and/or blocked. Affected Users can contact Pora to contest the decision.
                    </span>
                    <span className="font-[600]">If you post anything that is inconsistent with our Terms and Conditions of Use, we reserve the right to terminate or restrict access to your Account.DISCLAIMER</span>
                </div>
                <div id="disclaimer" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Disclaimer</span>
                    <span className="">THE APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE ALL PROVIDED TO YOU “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DO NOT GUARANTEE THE COMPATIBILITY OF ANY MATCHES.</span>
                    <span className="">SHOULD APPLICABLE LAW NOT PERMIT THE FOREGOING EXCLUSION OF EXPRESS OR IMPLIED WARRANTIES, THEN WE GRANT THE MINIMUM EXPRESS OR IMPLIED WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS SECTION.</span>
                    <span className="">ADDITIONALLY, WE DO NOT MAKE ANY WARRANTIES THAT THE APP OR SITE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE OR THAT YOUR USE OF THE APP OR SITE WILL MEET YOUR EXPECTATIONS, OR THAT THE APP, SITE, OUR CONTENT, ANY MEMBER CONTENT, OR ANY PORTION THEREOF, IS CORRECT, ACCURATE, OR RELIABLE. YOUR USE OF THE APP OR SITE IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. PORA INC IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER. PORA INC DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS.</span>
                </div>
                <div id="indemnity" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Indemnity</span>
                    <span className="">All the actions you make and information you post on Pora remain your responsibility. Therefore, you agree to indemnify, defend, release, and hold us, and our partners, licensors, affiliates, contractors, officers, directors, employees, representatives and agents, harmless, from and against any third party claims, damages (actual and/or consequential), actions, proceedings, demands, losses, liabilities, costs and expenses (including reasonable legal fees) suffered or reasonably incurred by us arising as a result of, or in connection with:</span>
                    <span className="pl-6">
                        <ul className="list-disc space-y-3">
                            <li>any negligent acts, omissions or wilful misconduct by you or other users;</li>
                            <li>No kids on their own. They must be in the photo with an adult, and fully clothed.</li>
                            <li>your access to and use of the App;</li>
                            <li>the uploading or submission of Content to the App by you or other users;</li>
                            <li>any breach of these Terms by you; and/or</li>
                            <li>your violation of any law or of any rights of any third party.</li>
                        </ul>
                    </span>
                </div>
                <div id="tar" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] font-[600] text-[24px]">Termination and Remedies</span>
                    <span>These Terms commence on the date you accept them (as described in the preamble) and continue until terminated in accordance with the terms herein.</span>
                    <span>If you want to terminate these Terms, you may do so by (a) notifying Pora Inc at any time, and (b) closing your Account. Your notice should be sent to Pora Inc’s address below. Please note that if you terminate these Terms, your subscription will continue until the end of the subscription period for which applicable fees have been paid, and you will not be entitled to a refund. Pora Inc may terminate or suspend these Terms, including your Account, if you breach these Terms or if Pora Inc is required to do so by applicable law. You agree that all terminations for cause shall be made in Pora Inc’s sole discretion and that Pora Inc shall not be liable to you or any third party for any termination of your Account.</span>
                    <span>In the event that Pora Inc determines, in its sole discretion, that you have breached any portion of these Terms, or have otherwise demonstrated conducted inappropriate for the App, Pora Inc reserves the right to: (a) warn you via email (to any email addresses you have provided) that you have violated the Terms; (b) delete your User Content; (c) discontinue your Account; (d) discontinue your subscription(s); (e) notify and/or send your User Content to and/or fully cooperate with the proper law enforcement authorities for further action; and/or (f) pursue to any other action which Pora Inc deems to be appropriate.</span>
                    <span>Termination of these Terms or your Account includes the removal of access to your Account, and all related information and content associated with or inside your Account.</span>
                </div>
                <div id="mis" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Miscellaneous</span>
                    <span>As Pora grows, we might have to make changes to these Terms, so we reserve the right to modify, amend or change the Terms at any time (a “Change”). If we do this then the Changes will be posted on this page, and we will indicate the Effective Date of the updates at the bottom of the Terms. In certain circumstances, we may send an email to you notifying you of a Change. It’s also possible that we might ask you to agree to our Changes. You should regularly check this page for notice of any Changes.</span>
                    <span>Your continued use of Pora following any Change constitutes your acceptance of the Change and you will be legally bound by the new updated Terms. If you do not accept any Changes to the Terms, you should stop using Pora immediately.</span>
                    <span className="space-y-1">
                        <p className="font-[600]">Additional items:</p>
                        <p>If, for any reason, any of the Terms are declared illegal, invalid or otherwise unenforceable by a court of a competent jurisdiction, then to the extent that term is illegal, invalid or unenforceable, it shall be severed and deleted from the Terms and the remainder of the Terms shall survive, remain in full force and effect and continue to be binding and enforceable.</p>
                    </span>
                    <span>No failure or delay in exercising any right, power or privilege under the Terms shall operate as a waiver of such right or acceptance of any variation of the Terms and nor shall any single or partial exercise by either party of any right, power or privilege preclude any further exercise of the right or the exercise of any other right, power or privilege.</span>
                    <span>By using the App, you agree and acknowledge that Pora is a global app operating through servers located in a number of countries around the world, including Canada and the United States. If you live in a country with data protection laws, the storage of your personal data may not provide you with the same protections as you enjoy in your country of residence. By submitting your personal information, or by choosing to upgrade the services you use, or by making use of the applications available on Pora, you agree to the transfer of your personal information to, and storage and processing of your personal information in, any such countries and destinations.</span>
                    <span>The App may contain links to third-party websites or resources. In such cases, you acknowledge and agree that we are not responsible or liable for:</span>
                    <span>
                        <ul className="list-decimal pl-5 py-2">
                            <li>the availability or accuracy of such websites or resources; or</li>
                            <li>the content, products, or services on or available from such websites or resources.</li>
                        </ul>
                    </span>
                    <span className="">Links to such websites or resources do not imply any endorsement. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources. Framing, in-line linking or other methods of association with the App are expressly prohibited without first obtaining our prior written approval.</span>
                    <span className="">These Terms, and any rights and licences granted hereunder, may not be transferred or assigned by you, but may be assigned by us without restriction.
                    In the event there is a discrepancy between this English language version and any translated copies of the Terms, the English version shall prevail.
                    </span>
                    <span>If you have any questions, complaints or claims with respect to the App, please contact us at <a href="mailto:support@poraapp.com" className="text-[#E1693E]">support@poraapp.com</a></span>
                </div>
                <div id="glaf" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Governing Law and Forum</span>
                    <span className="">This agreement has been made in and shall be construed in accordance with the laws of the Province of Ontario. By using this App, you agree to the sole and exclusive venue and jurisdiction of the provincial courts of Ontario and federal courts of Canada, for any and all disputes arising out of or related to this agreement or the use of this App.</span>
                </div>
                <div id="ed" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Effective Date</span>
                    <span className="">This Privacy Policy is effective: 14 February 2022.</span>
                </div>
            </div>
        </div>
   )
}

export default PrivacyBody