import React from "react"

const Newsletter = () => {
    return (
        <div className="grid grid-cols-1 text-center bg-[#FAFAFA] pt-10 pb-10 mb-10">
            <div className="col-span-1 text-[#333333] font-[700] text-[24px] lg:text-[40px] leading-[48px] mx-auto">Sign up to our newsletter</div>
            <div className="col-span-1 text-[#4F4F4F] font-[400] text-[16px] lg:text-[24px] leading-[28px] mx-auto mt-6 w-[90%]">Be the first to get notified when we have new posts.</div>
            <div className="col-span-1 w-[88%] lg:w-[65%] mx-auto mt-8">
                <label className="sr-only">Label</label>
                <div className="flex rounded-md shadow-sm">
                <input type="text" id="hs-trailing-button-add-on" name="hs-trailing-button-add-on" className="py-3 px-4 w-full block border-gray-200 shadow-sm rounded-l-md text-sm focus:z-10 border-[1px] focus:outline-[#E1693E] focus:border-[#E1693E] focus:ring-blue-500" placeholder="Enter email address"/>
                <button type="button" className="ease-in duration-500 py-3 w-[30%] px-4 inline-flex flex-shrink-0 justify-center items-center gap-2 rounded-r-md border border-transparent font-semibold bg-[#E1693E] text-white hover:text-[#333333] hover:bg-[#FAFAFA] hover:border-[1px] hover:border-[#E1693E] focus:z-10 focus:outline-[#E1693E] focus:ring-2 focus:ring-[#E1693E] transition-all text-sm">
                    Subscribe
                </button>
                </div>
            </div>
        </div>
    )
}

export default Newsletter