import logo from './logo.svg';
import './App.css';
import Home from './components/landing'
import React from 'react';
import AllBlogs from './components/blog/all';
import Details from './components/blog/details';
import PrivateMatchingEmail from './components/private/email';
import PrivateMatchingOtp from './components/private/otp';
import PrivateMatching from './components/private';
import PrivateMatchingHome from './components/private/home';
import Elite from './components/elite/home';
import Payment from './components/private/payment';
import Terms from './components/terms';
import Privacy from './components/privacy';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch
} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={< Home />}></Route>
          <Route path="/blog/:id" element={<Details />} />
          <Route exact path='/blog' element={< AllBlogs />}></Route>
          <Route exact path='/private-matching' element={< PrivateMatchingHome />}></Route> 
          <Route exact path='/elite' element={< Elite />}></Route>
          <Route exact path='/verify-email' element={< PrivateMatchingEmail />}></Route>
          <Route exact path='/verify-otp' element={< PrivateMatchingOtp />}></Route>
          <Route exact path='/create-account' element={< PrivateMatching />}></Route>
          <Route exact path='/payment' element={< Payment />}></Route>
          <Route exact path='/terms' element={< Terms />}></Route>
          <Route exact path='/privacy' element={< Privacy />}></Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
