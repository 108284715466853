import React from 'react'
import about from "../../images/joinelite.svg"
import about2 from "../../images/elitetype.svg"
import how from "../../images/elitehow.svg"
import smoother from "../../images/smoother.svg"
import safe from "../../images/safe.svg"
import harass from "../../images/harass.svg"

function homeBody() {

const join = () =>{
    if (window.navigator.platform.indexOf("Win") != -1 || window.navigator.platform.indexOf("Android") != -1) {
        window.location.href='https://play.google.com/store/apps/details?id=com.getpora.android.app'
    }else if (window.navigator.platform.indexOf("Mac") != -1 || window.navigator.platform.indexOf("iPhone") != -1 || window.navigator.platform.indexOf("iPad") != -1 || window.navigator.platform.indexOf("iPod") != -1) {
        window.location.href='https://www.apple.com/app-store/'
    } else {
        window.location.href='https://play.google.com/store/apps/details?id=com.getpora.android.app'
    }
}
  return (
    <>
        <div className='grid grid-cols-1 px-5 lg:px-8'>
            <div className="col-span-1 lg:mx-10 bg-[url('images/elitehero.svg')] flex flex-col text-white mb-10 items-center justify-center space-y-9 py-10 lg:py-20 mt-5 lg:mt-10">
                <p className='font-[700] text-[30px] lg:text-[60px] leading-[40px] lg:leading-[77px] text-center'>Welcome to Pora Elite</p>
                <p className='text-center font-[400] text-[20px] leading-[29px] w-[84%]'>A more personalised dating pool. Your profile will not be displayed to the thousands of people registered on the Basic and Premium packages. You will be served by a package more suited to your needs.</p>
                <button onClick={join} className='bg-[#E1693E] rounded-[8px] p-3 px-6'>Join now</button>
            </div>
        </div>
        <div className='grid grid-cols-1 px-5 lg:px-8'>
            <div className='col-span-1 lg:mt-10 grid grid-cols-2 gap-x-10 lg:mx-10'>
                <div className='col-span-2 lg:col-span-1 flex flex-col my-auto space-y-4'>
                    <p className='text-black text-[32px] lg:text-[45px] font-[700] lg:mb-5'>Join Pora Elite</p>
                    <div className='lg:hidden col-span-2 lg:col-span-1 '>
                        <img src={about} />
                    </div>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>Pora Elite offers a more serious, personalised dating service. This means that your profile will not be displayed to the thousands of people registered on the Basic and Premium package.</p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>You will be served by a package more suited to your needs.</p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>With Pora Elite, you can micro-select your connections through a deeper filtering process selection on background, values, attitudes, opinions, interests, personality, and lifestyles</p>
                    <button onClick={join} className='bg-[#E1693E] rounded-[8px] p-3 px-6 text-white w-[158px]'>Join now</button>
                </div>
                <div className='hidden lg:block col-span-2 lg:col-span-1 '>
                    <img src={about} />
                </div>
            </div>
        </div>
        <div className='grid grid-cols-1 px-5 lg:px-8 mt-5 lg:mt-10'>
            <div className='col-span-1 lg:mt-10 grid grid-cols-2 gap-x-10 lg:mx-10'>
                <div className='hidden lg:block col-span-2 lg:col-span-1 '>
                    <img src={about2} />
                </div>
                <div className='col-span-2 lg:col-span-1 flex flex-col my-auto space-y-4'>
                    <p className='text-black text-[32px] leading-[40px] lg:text-[45px] font-[700] lg:leading-[50.09px]'>What type of people will you match with? </p>
                    <div className='lg:hidden col-span-2 lg:col-span-1 '>
                        <img src={about2} />
                    </div>
                    <p className=''></p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>Professionals and accomplished cosmopolitan singles. Pora Elite is designed for singles who desire a solid, long-lasting commitment.</p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>Majority of our members who upgrade to Elite are relatively affluent and well educated and that’s what Elite is designed for.</p>
                    <button onClick={join} className='bg-[#E1693E] rounded-[8px] p-3 px-6 text-white lg:w-[30%] text-center'>Join now</button>
                </div>
            </div>
        </div>
        <div className='grid grid-cols-1 px-8 bg-[#FDF7F5] mt-10 lg:mt-20 lg:mb-10'>
            <div className='col-span-1 mt-10 grid grid-cols-2 gap-x-20 lg:mx-10 mb-10 '>
                <div className='col-span-2 lg:col-span-1 flex flex-col my-auto'>
                    <p className='text-black text-[32px] lg:text-[45px] font-[700] mb-5 lg:mb-auto'>How does it work?</p>
                    <div className='lg:hidden col-span-1 '>
                        <img src={how} />
                    </div>
                    <div className='flex flex-col space-y-5 pt-5 lg:pt-auto'>
                        <div className='grid grid-cols-8 lg:space-x-6'>
                            <span className='col-span-2 lg:col-span-1 border-[1px] border-[#333333] rounded-full w-[56px] h-[56px] text-center grid'><p className='my-auto text-[#E1693E] text-[26px] '>1</p></span>
                            <div className='col-span-6 lg:col-span-7'>
                                <p className='text-[#E1693E] text-[22px] lg:text-[24px]'>Download the app</p>
                                <p className='mt-2'>Get the Pora App on Google Play Store for Android or App Store for iOS.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 lg:space-x-6'>
                            <span className='col-span-2 lg:col-span-1 border-[1px] border-[#333333] rounded-full w-[56px] h-[56px] text-center grid'><p className='my-auto text-[#E1693E] text-[26px] '>2</p></span>
                            <div className='col-span-6 lg:col-span-7'>
                                <p className='text-[#E1693E] text-[22px] lg:text-[24px]'>Create a profile</p>
                                <p className='mt-2'>You fill out a form. We catalogue your values, goals, desires and interests. Once you are accepted on the Elite platform and have paid for the upgrade, we place your profile in a pool of other Elite users.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 lg:space-x-6'>
                            <span className='col-span-2 lg:col-span-1 border-[1px] border-[#333333] rounded-full w-[56px] h-[56px] text-center grid'><p className='my-auto text-[#E1693E] text-[26px] '>3</p></span>
                            <div className='col-span-6 lg:col-span-7'>
                                <p className='text-[#E1693E] text-[22px] lg:text-[24px]'>Start Chatting with other Elites</p>
                                <p className='mt-2'>Start interacting people who have indicated similar pointers in their match preference and only showcase to you the profiles of the top relatable potentials for your dating pool.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hidden lg:block col-span-1 '>
                    <img src={how} />
                </div>
            </div>
        </div>
        <div className='grid grid-cols-6 px-8 mb-20 gap-x-4 gap-y-5'>
            <div className='col-span-6 text-center mt-5 '><p className='text-black text-[32px] lg:text-[45px] font-[700]'>What you get as an Elite member</p></div>
            <div className='col-span-6 lg:col-span-2 rounded-[8px] border-[1px] px-4 pt-10 flex flex-col space-y-4 pb-4'>
                <span><img src={smoother} /></span>
                <span className='text-[#131313] font-[600] text-[20px]'>A smoother browsing experience</span>
                <span className='text-[#4F4F4F]'>Pora Elite enables you filter through profiles on a deeper level than the Premium package. You can include educational background, and schools attended, as descriptive features for your narrowed-down selection for connections. </span>
            </div>
            <div className='col-span-6 lg:col-span-2 rounded-[8px] border-[1px] px-4 pt-10 flex flex-col space-y-4 pb-4'>
                <span><img src={safe} /></span>
                <span className='text-[#131313] font-[600] text-[20px]'>We promise you, your data is safe</span>
                <span className='text-[#4F4F4F]'>We value your privacy. A user’s specific location is never visible to other users or to us here at Pora. We only save the data you have answered in the app and will not share your personal info outside of our database. </span>
            </div>
            <div className='col-span-6 lg:col-span-2 rounded-[8px] border-[1px] px-4 pt-10 flex flex-col space-y-4 pb-4'>
                <span><img src={harass} /></span>
                <span className='text-[#131313] font-[600] text-[20px]'>We guarantee, no one will harass you</span>
                <span className='text-[#4F4F4F]'>Pora users can only chat with one another if they have both liked each other so there’s no fear of spamming. </span>
            </div>
        </div>
    </>
  )
}

export default homeBody