import React from "react";
import Header from "../../header"
import Hero from "../hero";
import Segments from "../segments";
import FooterOne from "../../footer/footer1"
import Newsletter from "../newsletter";

const AllBlogs = () =>{
    return (
        <>
        <Header></Header>
        <Hero></Hero>
        <Segments></Segments>
        <Newsletter></Newsletter>
        <FooterOne></FooterOne>
        </>
    )
}

export default AllBlogs