import React from "react"
import Easy from "../../images/easy.svg"
import Verification from "../../images/verification.svg"
import Guide from "../../images/guide.svg"
import Customer from "../../images/customer.svg"
import Pricing from "../../images/pricing.svg"

const Features = () => {
    return (
        <div className="grid grid-cols-3 p-5 lg:p-20" id="features">
            <div className="col-span-3 mx-auto">
                <div className="bg-[#2F80ED] mx-auto font-[600] text-white rounded-[24px] w-[124px] py-2 h-[46px] text-center">
                    <p className="my-auto">Features</p>
                </div>
            </div>
            <div className="col-span-3 text-[#000] text-[30px] font-[600] lg:text-[48px] mx-auto mt-4 wow jello" data-wow-duration="3s">Why Choose Pora ?</div>
            <div className="col-span-3 mx-auto mb-5 text-[#333333] mt-4">
                <p>
                    Pora is built for the high end of the singles 
                    pool for Africans at home and in the Diaspora.
                </p>
            </div>
            <div className="col-span-3 lg:col-span-1 mt-5">
                <div className="ease-in duration-500 cursor-pointer grid grid-cols-1 gap-4 hover:shadow-[0_12px_50px_rgba(107,107,107,0.06)] rounded-[16px] p-5">
                    <div className="col-span-1">
                        <img src={Easy} alt="Easy"/>
                    </div>
                    <div className="col-span-1 text-[#000] font-[500] text-[18px]">
                        <p>Easy Sign up process</p>
                    </div>
                    <div className="col-span-1 text-[#333333]">
                        <p>With our brilliant user interface and 
                            straightforward sign up questions you can start 
                            discovering profiles of users just like you 
                            in under 10 minutes.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-span-3 lg:col-span-1 mt-5">
                <div className="ease-in duration-500 cursor-pointer grid grid-cols-1 gap-4 hover:shadow-[0_12px_50px_rgba(107,107,107,0.06)] rounded-[16px] p-5">
                    <div className="col-span-1">
                        <img src={Verification} alt="Easy"/>
                    </div>
                    <div className="col-span-1 text-[#000] font-[500] text-[18px]">
                        <p>Photo and Profile verification</p>
                    </div>
                    <div className="col-span-1 text-[#333333]">
                        <p>Your photos and profile speak volumes. 
                            We want you to put your best foot forward 
                            so we will not publish your profile unless it 
                            is picture perfect! We want others to see you at your very best.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-span-3 lg:col-span-1 mt-5">
                <div className="ease-in duration-500 cursor-pointer grid grid-cols-1 gap-4 hover:shadow-[0_12px_50px_rgba(107,107,107,0.06)] rounded-[16px] p-5">
                    <div className="col-span-1">
                        <img src={Customer} alt="Easy"/>
                    </div>
                    <div className="col-span-1 text-[#000] font-[500] text-[18px]">
                        <p>24 hour Customer Support</p>
                    </div>
                    <div className="col-span-1 text-[#333333]">
                        <p>Because Pora can be downloaded anywhere we 
                            are available to support users regardless of time zone
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-span-3 lg:col-span-1 mt-5">
                <div className="ease-in duration-500 cursor-pointer grid grid-cols-1 gap-4 hover:shadow-[0_12px_50px_rgba(107,107,107,0.06)] rounded-[16px] p-5">
                    <div className="col-span-1">
                        <img src={Pricing} alt="Easy"/>
                    </div>
                    <div className="col-span-1 text-[#000] font-[500] text-[18px]">
                        <p>Competitive Pricing</p>
                    </div>
                    <div className="col-span-1 text-[#333333]">
                        <p>We are not cheap neither are we over priced. 
                            We are affordable to everyone in our target space.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features