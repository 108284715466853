import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import Header from "../../header";
import FooterOne from "../../footer/footer1";
import { Link} from "react-router-dom";
const Details = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    var settings = {
        method: 'GET',
        headers: myHeaders
    };

    const [singleBlog, setSingleBlog] = useState([])
    let {id} = useParams()

    const url = window.location.pathname.split('/').pop();
    useEffect(() => {
        getBlogs()
        getRelated()
    }, [url])

    useEffect(()=>{
        const getBlogs = async () =>{
            fetch('https://backend.poraapp.com/api/v1/get-blog/'+id,settings)
            .then((res) => {
                return (res.json())
            }).then((data) => {
                setSingleBlog(data)
                setBlogText(data[0].body)
            }).catch((e) => {
                console.log(e)
            })
        }
        getBlogs()
    },[])

    const [blogs,setBlogs] = useState([])
    useEffect(()=>{
        const getBlogs = async () =>{
            fetch('https://backend.poraapp.com/api/v1/related-blogs/'+id,settings)
            .then((res) => {
                return (res.json())
            }).then((data) => {
                setBlogs(data)

            }).catch((e) => {
                console.log(e)
            })
        }
        getBlogs()
    },[])

    const getBlogs = async () =>{
        fetch('https://backend.poraapp.com/api/v1/get-blog/'+id,settings)
        .then((res) => {
            return (res.json())
        }).then((data) => {
            setSingleBlog(data)
            setBlogText(data[0].body)
        }).catch((e) => {
            console.log(e)
        })
    }

    const getRelated = async () =>{
        fetch('https://backend.poraapp.com/api/v1/related-blogs/'+id,settings)
        .then((res) => {
            return (res.json())
        }).then((data) => {
            setBlogs(data)

        }).catch((e) => {
            console.log(e)
        })
    }

    const [blog_text, setBlogText] = useState()


     function replaceWithBr() {
        return blog_text.replace(/\r\n/g, "<br/>")
      }

    const goSingle = (id) => {
        sessionStorage.setItem('id',id);
        window.location.href="/blog/"+id
    }

    return (
        <>
        <Header></Header>
        <div className="grid grid-cols-1 p-5 lg:p-20">
            {
                singleBlog.map(({id, body, image, date,title})=>{
                    return (
                        <>
                        <div className="col-span-1"><img className="rounded-[8px]" src={`https://backend.poraapp.com/storage/blogs/`+image} alt="details title"/></div>
                        <p className="col-span-1 text-[#5E5E5E] text-[18px] leading-[20px] lg:text-[20px] font-[400] lg:leading-[27px] mt-2">Published {date}</p>
                        <p className="col-span-1 text-[28px] lg:text-[48px] text-[#222222] leading-[120%] font-[700] w-[80%] mt-8">{title}</p>
                        <p dangerouslySetInnerHTML={{__html: replaceWithBr()}} className="col-span-1 w-[100%] text-[#4F4F4F] text-[16px] lg:text-[18px] font-[400] leading-[170%] mt-5"/>
                        {/* <p className="col-span-1 w-[100%] text-[#4F4F4F] text-[16px] lg:text-[18px] font-[400] leading-[170%] mt-5">{blog_text}</p> */}
                        </>
                    )
                })
            }

        </div>
        

        <div className="grid grid-cols-3 pl-5 pr-5 lg:pl-20 lg:pr-20 mb-20">
            <p className="col-span-3 text-[#222222] font-[600] text-[28px] lg:text-[48px] leading-[58px] text-center mb-10">Related Articles</p>
            {blogs.length > 0 ? 
            blogs.map(({id, title, body, image})=>{
                return (
                    <div className="col-span-3 lg:col-span-1 rounded-[5px] p-5 shadow-[0px_60px_80px_-32px_rgba(0,0,0,0.09)]">
                        <div className="grid grid-cols-1 mx-auto flex items-center justify-center lg:gap-5">
                            <img src={`https://backend.poraapp.com/storage/blogs/`+image} className="col-span-1 mx-auto rounded-[5px]"></img>
                            <div className="text-[#222222] font-[600] text-[24px] leading-[36px] lg:mt-7">{title}</div>
                            <p className="text-[#4F4F4F] font-[400] text-[16px] leading-[28px] lg:mt-2">{body.substr(0, 100)}...</p>
                            <Link to={`/blog/${id}`} className="cursor-pointer text-[#E1693E] font-[600] text-[14px] leading-[17px] underline lg:mt-5">READ ARTICLE</Link>
                        </div>
                    </div>
                )
            }) : 
            <div className="col-span-3 text-[#333333] font-[600] text-[24px]">
                No related articles.
            </div>
        }
        </div>
        <FooterOne></FooterOne>
        </>
    )
}

export default Details
