import React, {useState} from "react";

const Faqs = () => {

    const [active, setActive] = useState(0)
    const response = (id) => {
        setActive(id)
        if(active == id){
            setActive(0)
        }
    }
    const Questions = [
        {
            id:1,
            question:'How long does it take for my account to be verified?',
            answer:`It can take 0 to 12 hours for an account to be verified. 
            During this time you will be able to browse other profiles 
            but your profile will not be visible to others and you will 
            not be able to upgrade your account status until your profile is verified.`
        },
        {
            id:2,
            question:'How can I pay for my Pora account?',
            answer:`Payments on Pora are powered by Google Playstore 
            if you have a Dollar credit card or by Paystack if
            you do not have a dollar denominated payment card.`
        },
        {
            id:3,
            question:'How can I cancel my Pora subscription?',
            answer:`Please send a request on the Contact Us 
            tab on your Account Page within the app and your Account
            will be cancelled within 12 hours.`
        },
        {
            id:4,
            question:'How can I block an account?',
            answer:`Go to the profile page of the account you are 
            interacting with and select the “block account” option.
            The blocked account will be unable to message you thereafter.`
        },
        {
            id:5,
            question:'I am not African or of African descent, can l set up my profile on Pora?',
            answer:`Yes you certainly can, especially if you are looking to date or make friends with an African.`
        }
    ]
    return (
        <div className="grid grid-cols-3 pr-5 pl-5 gap-4 lg:pl-20 lg:pr-20 mb-20 mt-10" id="faqs">
            <div className="col-span-3 mx-auto">
                <div className="bg-[#219653] mx-auto font-[600] text-white rounded-[24px] w-[94px] py-2 h-[44px] text-center">
                    <p className="my-auto">FAQs</p>
                </div>
            </div>
            <div className="col-span-3 text-[#000] text-[30px] font-[600] lg:text-[48px] mx-auto wow swing" data-wow-duration="1.5s">Frequently Asked Questions</div>
            <div className="col-span-3 mx-auto mb-5 text-[#333333]">
                <p>
                    Everything you need to know about our services and operations
                </p>
            </div>
            {Questions.map(({id,question,answer}) => {
                return (
                    <div key={id} className="col-span-3 w-[100%] lg:w-[60%] mx-auto p-4 rounded-[6px] border transition ease-in-out delay-150">
                        <div className="grid grid-cols-8" style={{color: active == id ? '#E1693E':'#333333'}}>
                            <div className="col-span-7">{question}</div>
                            <div className="col-span-1 ml-auto cursor-pointer" onClick={() => response(id)}> {active == id ? '-' : '+'} </div>
                        </div>
                        {active == id ? (
                            <div className="h pt-2">
                                <p>{answer}</p>
                            </div>
                        ) : <></>
                    }

                    </div>
                )
            })}
        </div>
    )
}

export default Faqs