import React from "react"
import AboutImage from "../../images/Rectangle 5.png"
import Casual from "../../images/casual.svg"
import Fake from "../../images/fake.svg"
import './about.scss'

const About = () => {
    return (
        <div className=" about-image grid grid-cols-2 p-5 gap-20 lg:p-20" id="about">
            <div className="hidden lg:block col-span-1 wow fadeInUp" data-wow-duration="2s">
                <img src={AboutImage} alt="About Image" className="aboutimage"/>
            </div>
            <div className="col-span-2 lg:col-span-1 my-auto">
                <div className="grid grid-cols-5">
                    <div className="col-span-5 wow tada" data-wow-delay=".5" data-wow-duration="1.5s">
                        <button className="bg-[#33658A] rounded-[24px] w-[146px] h-[46px] text-white">About Pora</button>
                    </div>
                    <div className="col-span-5 text-[#000000] leading-[120%] text-[30px] lg:text-[25px] font-[600] mt-4">
                        A Purposeful Networking App for Africans In Diaspora
                    </div>
                    <div className="lg:hidden col-span-5 mt-4 wow fadeInUp" data-wow-duration="2s">
                        <img src={AboutImage} alt="About Image"/>
                    </div>
                    <div className="col-span-5 text-[16px] mt-4 space-y-4">
                    <p>At PORA, our mission is to unite and empower Africans in Diaspora worldwide through our Social networking app. Our app acts as a global bridge, forging connections that transcend borders. </p>
                    <p>Whether you seek networking, business, friendship, romance, career advancement, or private matching, PORA is designed for you.</p>
                    <p>What sets us apart is our commitment to fostering quality connections. With a strong presence in Canada,the UK and USA, we celebrate diversity, welcoming Africans in Diaspora with open arms. Join us in shaping a brighter future, where the Africans in Diaspora thrives in unity.</p>
                    <p>Explore boundless possibilities with PORA – where ambition, relationships, and opportunity converge. Embark on your journey with us today."</p>
                    </div>
                    <div className="col-span-5 mt-4 hidden lg:block">
                        <div className="grid grid-cols-2">
                            <div className="col-span-2 lg:col-span-1">
                                <div className="grid grid-cols-8 gap-2">
                                    <div className="col-span-1"><img src={Casual} alt="No casual hookups"/></div>
                                    <div className="col-span-7">No Casual Hookups</div>
                                </div>
                            </div>
                            <div className="col-span-2 lg:col-span-1">
                                <div className="grid grid-cols-8 gap-2">
                                    <div className="col-span-1"><img src={Fake} alt="No fake profiles"/></div>
                                    <div className="col-span-7">No Fake Profiles</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 mt-4">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.getpora.android.app"><button className="bg-[#E1693E] rounded-[8px] text-white w-[100%] lg:w-[195px] h-[54px] ease-in duration-500 hover:border-[1px] hover:bg-white hover:border-[#E1693E] hover:text-[#333333]">Download Now</button></a>
                    </div>
                </div>
            </div>
        </div>
    )   
}

export default About