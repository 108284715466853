import React from "react";
import Header from "../header";
import Hero from "../hero"
import Marrquee from "../marque"
import About from "../about";
import Unique from "../unique";
import Features from "../features";
import Plans from "../plans"
import Footer from "../footer";
import Faqs from "../faqs";

const Home = () => {
   return ( 
        <div className="font-Inter">
         <Header></Header>
         <Hero></Hero>
         {/* <Marrquee></Marrquee> */}
         <About></About>
         <Unique></Unique>
         <Features></Features>
         <Plans></Plans>
         <Faqs></Faqs>
         <Footer></Footer>
        </div>
   )
}

export default Home