import React, {useState} from "react"
import Check from "../../images/check.png"
import BlackCheck from "../../images/blackcheck.png"
import PoraElite from "../../images/pora-elite.png"

const Plans = () => {
    const [premium, setPremium] = useState('19.99');
    const [elite, setElite] = useState('49.99');

    const [duration,setDuration] = useState('month')
    //setBasic()
    const Change = (e) => {
        if(e.target.checked){
            setDuration('3-months')
            setPremium('49.99')
            setElite('199.99')
        }else{
            setDuration('month')
            setPremium('19.99')
            setElite('49.99')
        }
    }
    return (
        <div className="grid grid-cols-3 pr-5 pl-5 gap-4 lg:pl-20 lg:pr-20" id="pricing">
            {/* <div className="col-span-3">
                <div className="grid grid-cols-2 lg:gap-5">
                    <div className="col-span-2 lg:col-span-1 bg-[#FFF6F3] rounded-[16px] pb-5 mb-10 lg:mt-[5px]" style={{background: `url(${PoraElite})`}}>
                        <div className="grid grid-cols-1 text-center">
                            <div className="col-span-1 font-[600] text-[32px] lg:text-[48px] pt-3 wow fadeIn" data-wow-duration="3s">Pora Elite</div>
                            <div className="col-span-1 mx-auto w-[80%] mt-5 font-[400] text-[#333333]">
                                Pora Elite is a referral only platform on the Pora app 
                                for users with pedigree and character.
                                <br/><br/>
                                To join Pora Elite download the App 
                                from Google Playstore (Available in Android only) and fill this <a target="blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfdXCyAuJtgX7wk75ZEnEFRG49dynd4lpFtSyUowldfikNPUg/viewform?vc=0&c=0&w=1&flr=0" style={{textDecoration:'underline'}} className="text-[#E1693E]">form</a>.
                                (Please note that your responses will be subject to verification, 
                                however they will not be visible to other users and will be 
                                treated with the utmost confidentiality ).
                            </div>
                            <div className="col-span-1 mt-5">
                                <a target="blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfdXCyAuJtgX7wk75ZEnEFRG49dynd4lpFtSyUowldfikNPUg/viewform?vc=0&c=0&w=1&flr=0"><button className="bg-[#E1693E] rounded-[8px] w-[295px] h-[46px] text-white ease-in duration-500 hover:border-[1px] hover:bg-[#FFF6F3] hover:border-[#E1693E] hover:text-[#333333]">Fill out this form to get started</button></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-2 lg:col-span-1 bg-[#FFF9F9] rounded-[16px] pb-5 mb-10 lg:mt-[5px]" >
                        <div className="grid grid-cols-1 text-center">
                            <div className="col-span-1 font-[600] text-[32px] lg:text-[48px] pt-3 wow fadeIn" data-wow-duration="3s">Private Matching</div>
                            <div className="col-span-1 mx-auto w-[80%] mt-5 font-[400] text-[#333333]">
                                Private matching is a feature on Pora for users who don’t want their profiles to be
                                visible on the App. <br/><br/>If you are camera shy or you just want some privacy, then
                                Pora private matching is for you.
                                Download the App from Google Playstore and opt for Private Matching.
                            </div>
                            <div className="col-span-1 mt-10 pt-7">
                                <a target="blank" href="https://play.google.com/store/apps/details?id=com.getpora.android.app"><button className="bg-[#E1693E] rounded-[8px] w-[295px] h-[46px] text-white ease-in duration-500 hover:border-[1px] hover:bg-[#FFF9F9] hover:border-[#E1693E] hover:text-[#333333]">Download the App</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="col-span-3 mx-auto wow fadeIn" data-wow-duration="3s">
                <div className="bg-[#EB5757] mx-auto font-[600] text-white rounded-[24px] w-[96px] py-2 h-[46px] text-center">
                    <p className="my-auto">Plans</p>
                </div>
            </div>
            <div className="col-span-3 text-[#000] text-[30px] font-[600] lg:text-[48px] mx-auto wow fadeIn" data-wow-duration="3s">Plans & Pricing</div>
            <div className="col-span-3 mx-auto mb-5 text-[#333333] wow fadeIn" data-wow-duration="3s">
                <p>
                    Enjoy a 30-day free trial with access to premium 
                    features so you can chat & connect without limits.
                </p>
            </div>
            <div className="col-span-3 mx-auto mb-5 text-[#333333]">
                <div className="grid grid-cols-8">
                    <div className="col-span-3 mx-auto">Monthly Billing</div>
                    <div className="col-span-2 mx-auto">
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" onClick={(e) => Change(e)} className="sr-only peer"/>
                            <div className="outline-none w-11 h-6 bg-gray-200 focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </div>
                    <div className="col-span-3 mx-auto">Quarterly Billing</div>
                </div>
            </div>
            <div className="hover:cursor-pointer col-span-3 lg:col-span-1 mt-5 h-[567px] text-[#000] shadow-[0px_1px_1px_1px_rgba(165,134,255,0.2)] wow fadeInUp" data-wow-duration="2s">
                <div className="grid  grid-cols-1 gap-4 p-5">
                    <div className="col-span-1 font-[500] text-[32px]">
                        <p>Basic</p>
                    </div>
                    <div className="col-span-1">
                        <p>This plan allows you to browse up to 10 profiles daily. 
                            However, you can't match or chat with anyone on the basic plan.
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p><span className="text-[36px]">$0</span>/ {duration}
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={BlackCheck} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">Browse 1O profiles daily</div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
            <div className="hover:cursor-pointer col-span-3 lg:col-span-1 mt-5 h-[567px] bg-[#D85C30] text-white shadow-[0_12px_50px_rgba(107,107,107,0.06)] wow fadeInDown" data-wow-duration="2s" data-wow-delay=".5s">
                <div className="grid  grid-cols-1 gap-4 p-5">
                    <div className="col-span-1 font-[500] text-[32px]">
                        <p>Premium</p>
                    </div>
                    <div className="col-span-1">
                        <p>The Premium plan provides you with more 
                            possibilities to help you find a genuine connection on the Pora app.
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p><span className="text-[36px]">${premium}</span>/ {duration}
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={Check} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">Unlimited profile views and likes</div>
                            </div> 
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={Check} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">See who’s already liked you</div>
                            </div>  
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={Check} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">72 hours to respond to your match info</div>
                            </div>  
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={Check} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">Unlimited rewinds</div>
                            </div>  
                        </p>
                    </div>
                </div>
            </div>
            <div className="hover:cursor-pointer col-span-3 lg:col-span-1 mt-5 h-[567px] text-[#000] shadow-[0px_1px_1px_1px_rgba(165,134,255,0.2)] wow fadeInUp" data-wow-duration="2s" data-wow-delay=".7s">
                <div className="grid  grid-cols-1 gap-4 p-5">
                    <div className="col-span-1 font-[500] text-[32px]">
                        <p>Pora Elite</p>
                    </div>
                    <div className="col-span-1">
                        <p>Our Elite subscription is by referral only. 
                            Pora Elite is a member-only community of singles.
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p><span className="text-[36px]">${elite}</span>/ {duration}
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={BlackCheck} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">Unlimited profile views and likes</div>
                            </div>
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={BlackCheck} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">See who’s already liked you</div>
                            </div>
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={BlackCheck} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">72 hours to respond to your match info</div>
                            </div>
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={BlackCheck} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">Unlimited rewinds</div>
                            </div>
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={BlackCheck} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">Refer up to 3 people to join.</div>
                            </div>
                        </p>
                        <p className="pt-3">
                            <div className="grid grid-cols-8">
                                <div className="col-span-1 my-auto"><img src={BlackCheck} /></div>
                                <div className="col-span-7 my-auto ml-[-10px]">Community of professionals and entrepreneurs</div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Plans