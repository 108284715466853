import React,{useState,useEffect} from "react";
import BlogImage from "../../../images/blog/blog1.png"
import Author from "../../../images/blog/author.svg"
import { Link } from "react-router-dom";

const Segments = () => {
    const [blogs,setBlogs] = useState([])
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    var settings = {
        method: 'GET',
        headers: myHeaders
    };
    let arrangement = []
    const [newArrangement,setNewArrangement] = useState([])
    const [activePage, setActivePage] = useState()
    const [activeBlogs, setActiveBlogs] = useState([])
    const [start, setStart] = useState();
    const [end, setEnd] = useState()
    useEffect(()=>{
        const getBlogs = async () =>{
            fetch('https://backend.poraapp.com/api/v1/blogs',settings)
            .then((res) => {
                return (res.json())
            }).then((data) => {
                setBlogs(data)
                Set();
                
            }).catch((e) => {
                console.log(e)
            })
        }
        getBlogs()
    },[])

    
    useEffect(()=>{
        const getBlogs = async () =>{
            fetch('https://backend.poraapp.com/api/v1/blogs',settings)
            .then((res) => {
                return (res.json())
            }).then((data) => {
                setBlogs(data)
                Set();
                
            }).catch((e) => {
                console.log(e)
            })
        }
        getBlogs()
    },[])

    const newCall = () =>{
        fetch('https://backend.poraapp.com/api/v1/blogs',settings)
        .then((res) => {
            return (res.json())
        }).then((data) => {
            setBlogs(data)
            Set();
        }).catch((e) => {
            console.log(e)
        })
    }

    const changePage = (id,start,end) => {
        setStart(start)
        setEnd(end)
        setActivePage(id)
        setActiveBlogs(blogs.slice(start,end))
    }
    const Previous = () => {
        let s = start
        let e = end
        if(activePage != 1){
            let idd = activePage - 1
            let start = s - 6
            let end = e-6
            changePage(idd,start,end)
        }
    }
    const Next = () => {
        let s = start
        let e = end
        if(activePage != newArrangement.length){
            let idd = activePage + 1
            let start = s + 6
            let end = e + 6
            changePage(idd,start,end)
        }
    }

    const[clicked, setClicked] = useState(0)

    const SetAll = () => {
        console.log(activeBlogs.length)
        while(activeBlogs.length < 1){
            console.log(activeBlogs.length)
            newCall()
        }
    }
    const Set = () => {
        console.log(activeBlogs)
        let remainder = blogs.length - 6
        if(remainder > 0){
            setActivePage(1)
            arrangement.push(
                {
                    id : 1,
                    start : 0,
                    end : 6
                }
           )
            setActiveBlogs(blogs.slice(0,6))
            let i = 2
            let current_length = 6
            let current_end = (current_length) + 6
            while(remainder > 0){
                remainder = remainder - 6
                arrangement.push(
                    {
                        id : i,
                        start : current_length,
                        end : current_end
                    }
                )              
                current_length = current_end 
                current_end = (current_length) + 6
                i++
            }
        }else{
            setActivePage(1)
            setActiveBlogs(blogs)
            arrangement.push(
                {
                id : 1,
                start : 0,
                end : 5
                }
            )
        }
        setNewArrangement(arrangement)
        document.getElementById('button').click()
    }

    return (
        <>
            {blogs.length > 0 ? 
                <div className="grid grid-cols-6 bg-[#fff] pt-5 p-5 lg:p-20 gap-10">
                    {
                        activeBlogs && activeBlogs.map(({id,image,title,author,author_image,date}) => { 
                            return (
                                <div key={id} className="col-span-8 sm:col-span-3 md:col-span-2 rounded-[15px] bg-[#FFFFFF] shadow-[0px_4px_100px_rgba(0,0,0,0.1)] p-5">
                                    <div className="grid grid-cols-1">
                                        <img className="col-span-1 rounded-[10px]" src={`https://backend.poraapp.com/storage/blogs/`+image} alt={title}></img>
                                        <p className="col-span-1 font-[600] text-[16px] leading-[19px] text-[#333333] mt-4">
                                            <Link to={`/blog/${id}`}>{title}</Link>
                                        </p>
                                        <p className="col-span-1 font-[400] text-[14px] leading-[17px] text-[#646464] mt-2">{date}</p>
                                        <div className="col-span-1 mt-4">
                                            <div className="grid grid-cols-8">
                                                <img className="my-auto col-span-1 rounded-[100%] w-[24px] h-[24px]" src={Author} alt={author}></img>
                                                <p className="my-auto col-span-7 font-[400] text-[14px] leading-[17px] text-[#333333] ml-[-4%]">Pora </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> : 
                <div className="text-[#333333] font-[600] text-[24px] p-5 lg:p-20">
                    Oops! No blog available.
                </div>
            }
            <button id="button" onClick={Set}></button>
        <div className="grid grid-cols-2 pl-5 lg:pl-20 gap-5 mb-20">
            <div className="col-span-2 lg:col-span-1">
                <div className="grid grid-cols-7">
                    <div onClick={Previous} className="col-span-1 w-[40px] h-[40px] md:w-[64px] md:h-[64px] border-[1px] border-[#BCBCBC] grid rounded-[6px] place-items-center cursor-pointer text-[#BCBCBC] hover:text-[#E1693E] ease-in duration-300">
                        <span className="my-auto">&lt;</span>
                    </div>

                    {    
                    blogs.length < 7 ? 
                    <div className="col-span-1 bg-[#E1693E] w-[40px] h-[40px] md:w-[64px] md:h-[64px] border-[1px] border-[#E1693E] grid rounded-[6px] place-items-center cursor-pointer">
                        <span className="my-auto text-[#ffffff] font-[800] text-[18px]">1</span>
                    </div>
                    :
                        newArrangement.map(({id,start,end}) => {
                            return (
                                <div onClick={() => changePage(id,start,end)} key={id} className={`col-span-1 w-[40px] h-[40px] md:w-[64px] md:h-[64px] border-[1px] border-[#E1693E] grid rounded-[6px] place-items-center cursor-pointer hover:bg-[#E1693E] ${activePage == id? 'bg-[#E1693E]' : 'bg-[#fff]'} ${activePage == id? 'text-[#fff]' : 'text-[#E1693E]'}  hover:text-white ease-in duration-300`}>
                                <span className="my-auto font-[800] text-[18px]">{id}</span>
                                </div>
                            )
                        })
                    }   
                    <div onClick={Next} className="col-span-1 w-[40px] h-[40px] md:w-[64px] md:h-[64px] border-[1px] border-[#BCBCBC] grid rounded-[6px] place-items-center cursor-pointer text-[#BCBCBC] hover:text-[#E1693E] ease-in duration-300">
                        <span className="my-auto">&gt;</span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Segments