import React from 'react'
import Hero from './hero'
import PrivateForm from './form'

function PrivateMatchingBody() {
  return (
    <>
        <div className='grid grid-cols-6 bg-[#fff]'>
            <div className='col-span-6'>
                <Hero></Hero>
            </div>
            <div className='col-span-6 pt-5 p-5 lg:p-20 gap-10'>
                <PrivateForm></PrivateForm>
            </div>
        </div>
    </>
  )
}

export default PrivateMatchingBody