import React, { useState } from "react";

const TermsBody = () => {
const [present, setPresent] = useState(1)
const sections = [{id:1,title:'Rules',dir:'rules'},{id:2,title:'Types of Content',dir:'toc'}
,{id:3,title:'Your Content',dir:'yc'},{id:4,title:'Pora Photo Guiline',dir:'ppg'},
{id:5,title:'Member Content',dir:'mc'}
,{id:6,title:'Our Content',dir:'oc'},{id:7,title:'Restrictions on the App',dir:'rota'}]
const displaySection = (id,dir) => {
    setPresent(id)
    document.getElementById(dir).scrollIntoView({
        behavior: 'smooth'
      })
}
   return ( 
        <div className="px-8 lg:p-10 lg:mx-10 grid grid-cols-8 gap-x-8 mb-10">
            <div className="hidden lg:flex col-span-2 flex flex-col space-y-3 text-[18px] font-[400] mt-20 pt-4">
                { sections.map(({id,title,dir},ind) => {
                    return (
                        <span onClick={()=>displaySection(id,dir)} key={ind} className={`cursor-pointer ${present == id ? 'text-[#E1693E]' : 'text-[#333333]'}`}>{title}</span>
                    )
                }) }
            </div>
            <div className="col-span-8 lg:col-span-6 flex flex-col space-y-4 text-[18px] text-[#4F4F4F] mt-7 lg:mt-auto">
                <div className="flex flex-col space-y-4">
                    <span className="text-black font-[700] text-[30px] lg:text-[48px]">Terms and Conditions</span>
                    <span className="text-[#333333] font-[400] text-[18px]">This is a contract between you and Pora Inc. Please take a few moments to read these Terms before enjoying the App. We want you to know yours and our rights before you use the Pora Application (or the “App”). When you access, view, or use the App, you will be legally bound by these Terms.</span>
                </div>
                <div id="rules" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Rules</span>
                    <span>Before you can use the App, you will need to register for an account (“Account”). In order to create an Account you must:</span>
                    <span className="pl-6">
                        <ul className="list-decimal space-y-3">
                            <li>be at least 21 years old or the age of majority to legally enter into a contract under the laws of your home country; and</li>
                            <li>be legally permitted to use the App by the laws of your home country.</li>
                        </ul>
                    </span>
                    <span>We use a combination of automated systems and a team of administrators to monitor and review accounts and messages for content that indicates breaches of these Terms. We reserve the right at our sole discretion to terminate or suspend any Account, restrict access to the App, or make use of any operational, technological, legal or other means available to enforce the Terms (including without limitation blocking specific IP addresses), at any time without liability and without the need to give you prior notice.</span>
                    <span>due to your conduct on the App, or your conduct with other users of the App (including your “offline” conduct), if we, in our sole discretion, determine your conduct was inappropriate or improper,</span>
                    <span>if we or our affiliates, in our or their sole discretion, determine your conduct on other apps operated by our affiliates was inappropriate or improper, or</span>
                    <span>for any reasons whatsoever that we deem, in our sole discretion, justifies termination. If your Account is terminated or suspended, you agree you will not receive a refund for any paid service or features you have already been charged for.</span>
                </div>
                <div id="toc" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Types of Content</span>
                    <span>There are three types of content that you will be able to access on the App:</span>
                    <span className="pl-6">
                        <ul className="list-decimal space-y-3">
                            <li>content that you upload and provide (“Your Content”);</li>
                            <li>content that members provide (“Member Content”); and</li>
                            <li>content that we provide (including, without limitation, database(s) and/or software) (“Our Content”).</li>
                        </ul>
                    </span>
                    <span className="text-[20px] font-[600] text-[#333333]">The following Content is not allowed on Pora: </span>
                    <span className="pl-3">
                        <ul className="list-disc space-y-3">
                            <li>Content that contains language or imagery which could be deemed offensive or is likely to harass, upset, embarrass, alarm or annoy any other person (including for example and without limitation, language that could be deemed discriminatory towards an individual’s race/tribe, colour, ethnicity, national origin, religion, disability, sexual orientation, gender expression, gender identity or physical appearance);</li>
                            <li>Content that is obscene, pornographic, violent or otherwise may offend human dignity (including for example and without limitation, language that could be deemed discriminatory towards an individual’s race/tribe, colour, ethnicity, national origin, religion, disability, sexual orientation, gender expression, gender identity or physical appearance);</li>
                            <li>Content that is abusive, insulting or threatening, discriminatory or which promotes or encourages racism, sexism, hatred or bigotry (including for example and without limitation, language that could be deemed discriminatory towards an individual’s race/tribe, colour, ethnicity, national origin, religion, disability, sexual orientation, gender expression, gender identity or physical appearance);</li>
                            <li>Content which encourages any illegal activity including, without limitation, terrorism, inciting racial/tribal hatred or the submission of which in itself constitutes committing a criminal offence;</li>
                            <li>Content that is defamatory or libellous.</li>
                            <li>Content which encourages any illegal activity including, without limitation, terrorism, inciting racial/tribal hatred or the submission of which in itself constitutes committing a criminal offence;</li>
                            <li>Content which involves the transmission of “junk” mail or “spam”;</li>
                            <li>Content which contains any spyware, adware, viruses, corrupt files, worm programmes or other malicious code designed to interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any data or personal information whether from Pora or otherwise;</li>
                            <li>Content which, itself, or the posting of which, infringes any third party’s rights (including, without limitation, intellectual property rights and privacy rights);</li>
                            <li>Content that shows images of another person which was created or distributed without that person’s consent;</li>
                            <li>Content which is harmful to minors; or</li>
                            <li>Content that impersonates any other person, including falsely stating your name.</li>
                        </ul>
                    </span>
                    <span>Pora Inc operates a zero-tolerance policy for this kind of content and any such user will be immediately removed from the App.</span>
                </div>
                <div id="yc" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Your Content</span>
                    <span className="text-[20px] font-[600] text-[#333333]">You agree that Your Content must comply with our Guidelines below;</span>
                    <span className="pl-3">
                        <ul className="list-disc space-y-3">
                            <li>You are solely responsible and liable for your Content and will indemnify, defend, release, and hold Pora Inc harmless from any claims made in connection with your content.</li>
                            <li>You may not display any personal contact or banking information on your individual profile page whether in relation to you or any other person (for example, names, home addresses or postcodes, telephone numbers, email addresses, URLs, credit/debit card or other banking details). If you do choose to reveal any personal information about yourself to other users, whether via email or otherwise, it is at your own risk. We encourage you to use the same caution in disclosing details about yourself to third parties online as you would under any other circumstances.</li>
                            <li>As Pora is a public community, your Content will be visible to other users of the App all around the world instantly - so make sure you are comfortable sharing your Content before you post. As such, you agree that your Content may be viewed by other users and any person visiting, participating in or who is sent a link to the App (e.g. individuals who receive a link to a user’s profile or shared content from other users).</li>
                            <li>By uploading your Content on Pora, you represent and warrant to us that you have all necessary rights and licences to do so, and automatically grant us a non-exclusive, royalty free, perpetual, worldwide licence to use your content in any way (including, without limitation, editing, copying, modifying, adapting, translating, reformatting, creating derivative works from, incorporating into other works, advertising, distributing and otherwise making available to the general public such content, whether in whole or in part and in any format or medium currently known or developed in the future).</li>
                            <li>We do not have any obligation to store your content.</li>
                            <li>Please respect all users and their beliefs, interests and property while on Pora. We take a strong stance against hate speech, rude or abusive behaviour, bullying, and misogyny. In addition, we encourage all of our users to report anyone who does not follow these behavioural guidelines.</li>
                        </ul>
                    </span>
                </div>
                <div id="ppg" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Pora Photo Guidlines</span>
                    <span className="pl-3">
                        <ul className="list-disc space-y-3">
                            <li>Please upload only your own photos. If you don’t own the rights to a photo or video, please don’t post it. Please do not post photos that are not of you. We also recommend using our photo verification tool to let other users know that your profile is legit.</li>
                            <li>No kids on their own. They must be in the photo with an adult, and fully clothed.</li>
                            <li>No photos in bikinis/swimwear indoors.</li>
                            <li>No pictures in underwear.</li>
                            <li>No Shirtless/underwear Mirror Selfies.</li>
                            <li>The face must be clearly visible in all photos.</li>
                            <li>No watermarks or text overlaid.</li>
                            <li>No pornographic material.</li>
                            <li>No graphic hunting photos.</li>
                            <li>No graphic hunting photos.</li>
                        </ul>
                    </span>
                    <span>Any photos that do not meet our photo guidelines will be promptly removed.</span>
                </div>
                <div id="mc" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] font-[600] text-[24px]">Member Content</span>
                    <span>Other members of Pora will also share content via the App. Member Content belongs to the user who posted the content, is stored on our servers and displayed via the App at the direction of the user providing the Member Content.
                    </span>
                    <span>You do not have any rights in relation to other users’ Member Content, you may only use other users’ personal information to the extent that your use of it matches Pora’s purpose of allowing people to connect. You may not use other users’ information for commercial purposes, to spam, to harass, stalk or to make unlawful threats. We reserve the right to terminate your Account if you misuse other users’ information..</span>
                </div>
                <div id="oc" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Our Content</span>
                    <span>Any other text, content, graphics, user interfaces, trademarks, logos, sounds, artwork, and other intellectual property appearing on Pora, as well as the Pora software and database(s), are owned, controlled or licensed by us and are protected by copyright, trademark, data, database rights and/or other intellectual property law rights. All right, title and interest in and to Our Content always remain with us.</span>
                    <span>We grant you a non-exclusive, limited, personal, non-transferable, revocable, licence to access and use Our Content, without the right to sublicense, under the following conditions:</span>
                    <span>
                        <ul className="list-decimal pl-5 py-3">
                            <li>You shall not use, sell, modify, or distribute Our Content except as permitted by the functionality of the App;</li>
                            <li>You shall not use our name in metatags, keywords and/or hidden text;</li>
                            <li>You shall not create derivative works from Our Content or scrape, disable, decompile, analyse or in any way commercially exploit Our Content, in whole or in part, in any way; and</li>
                            <li>You shall use Our Content for lawful purposes only.</li>
                        </ul>
                    </span>
                    <span className="font-[600] text-[#333333]">No Obligation to Pre-Screen Content.</span>
                    <span className="">We generally try to avoid getting in the way and therefore don’t assume any obligation to pre-screen any of Your Content or any Member Content. However, there may be times where we need to step in, and we reserve the right to review, pre-screen, refuse and/or remove any Member Content and Your Content, including content exchanged between users in direct messages.</span>
                </div>
                <div id="rota" className="flex flex-col space-y-3">
                    <span className="text-[#E1693E] text-[24px] font-[600]">Restrictions of the App</span>
                    <span className="text-[20px] font-[600] text-[#333333]">You agree to</span>
                    <span className="pl-3">
                        <ul className="list-disc space-y-3">
                            <li>comply with all applicable laws, including without limitation, privacy laws, intellectual property laws, anti-spam laws, equal opportunity laws and regulatory requirements.;</li>
                            <li>Use your real name and real age in creating your Pora account and on your profile; and</li>
                            <li>use the services in a professional manner.</li>
                        </ul>
                    </span>
                    <span className="text-[20px] font-[600] text-[#333333]">You agree that you will not: </span>
                    <span className="pl-3">
                        <ul className="list-disc space-y-3">
                            <li>act in an unlawful or unprofessional manner including being dishonest, abusive or discriminatory;</li>
                            <li>misrepresent your identity, your age, your current or previous positions, qualifications or affiliations with a person or entity;</li>
                            <li>disclose information that you do not have the consent to disclose;</li>
                            <li>stalk or harass any other user of the App;</li>
                            <li>create or operate a pyramid scheme, fraud or other similar practice, or;</li>
                            <li>develop, support or use software, devices, scripts, robots, other types of mobile code or any other means or processes (including crawlers, browser plugins and add-on or other technology) to scrape or otherwise exfiltrate from Pora or its services, or otherwise copy profiles and other data from the services.</li>
                        </ul>
                    </span>
                    <span className="">You can report any abuse or complain about Member Content by contacting us, outlining the abuse and/or complaint. We reserve the right to investigate any possible violations of these Terms, any Pora user’s rights, or any third party rights and we may, in our sole discretion, immediately terminate any user’s right to use of the App without prior notice.</span>
                    <span className="">We don’t control any of the things our users say or do, so you are solely responsible for your interactions with other users of the App.</span>
                    <span className="font-[700] text-[#1C1C1C]">YOU UNDERSTAND THAT PORA INC DOES NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS. PORA INC ALSO DOES NOT INQUIRE INTO THE BACKGROUNDS OF ITS USERS OR ATTEMPT TO VERIFY THE STATEMENTS OF ITS USERS. PORA INC MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF ANY USERS OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS.</span>
                    <span className="">You agree to, and hereby do, release Pora Inc and its successors from any claims, demands, losses, damages, rights, and actions of any kind, including personal injuries, death, and property damage, that either directly or indirectly arises from your interactions with or conduct of other users of the App.</span>
                </div>
            </div>
        </div>
   )
}

export default TermsBody