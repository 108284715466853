import React from "react"
import UniqueImage from "../../images/unique.svg"
import Exclusive from "../../images/exclusive.svg"
import Support from "../../images/support.svg"
import Safety from "../../images/safety.svg"
import Networking from '../../images/networking.png'
import award from '../../images/award.png'
import Career from '../../images/career.png'


const Unique = () => {
    return (
        <div className="grid grid-cols-2 p-5 lg:p-20" id="uniqueness">
            <div className="col-span-2">
                <div className="bg-[#00AFB9] mx-auto font-[600] text-white rounded-[24px] w-[251px] py-2 h-[46px] text-center">
                    <p className="my-auto">Our Unique Proposition</p>
                </div>
            </div>
            <div className="col-span-2 text-[#000000] text-[30px] font-[600] lg:text-[48px] mx-auto text-center wow fadeIn" data-wow-duration="4s">
                <p>How we are Different</p>
            </div>
            {/* <div className="col-span-2 mx-auto text-center wow fadeIn" data-wow-duration="4s">
                <p>
                    Pora is built for the high end of the singles 
                    pool for Africans at home and in the Diaspora.
                </p>
            </div> */}
            <div className="col-span-2 lg:hidden lg:col-span-1 pt-5 wow fadeInUp" data-wow-duration="3s">
                <img src={UniqueImage} alt="Uniqueness"/>
            </div>
            <div className="col-span-2 mt-5 lg:mt-10">
                <div className="grid grid-cols-2">
                    <div className="col-span-2 lg:col-span-1 my-auto">
                        <div className="grid grid-cols-1">
                            <div className="ease-in duration-500 col-span-1 rounded-[16px] p-3 cursor-pointer hover:shadow-[4px_8px_70px_rgba(0,0,0,0.04)]">
                                <div className="grid grid-cols-8 gap-5">
                                    <div className="col-span-1"><img src={Exclusive} alt="exclusive"/></div>
                                    <div className="col-span-7">
                                        <p className="text-[18px] font-[600]">Cultural Connectivity</p>
                                        <p>PORA promotes cultural connectivity as it allows you to share and celebrate your unique cultural uniqueness with fellow Africans in diaspora.
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="ease-in duration-500 col-span-1 rounded-[16px] p-3 cursor-pointer hover:shadow-[4px_8px_70px_rgba(0,0,0,0.04)] mt-4">
                               <div className="grid grid-cols-8 gap-5">
                                <div className="col-span-1"><img src={Networking} alt="Professional Networking"/></div>
                                    <div className="col-span-7">
                                        <p className="text-[18px] font-[600]">Professional Networking</p>
                                        <p>PORA as a social networking app is your gateway to the Africans in diaspora professional network, connecting you with like-minded professionals.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ease-in duration-500 col-span-1 rounded-[16px] p-3 cursor-pointer hover:shadow-[4px_8px_70px_rgba(0,0,0,0.04)] mt-4">
                                <div className="grid grid-cols-8 gap-5">
                                    <div className="col-span-1"><img src={Career} alt="Career Development"/></div>
                                        <div className="col-span-7">
                                            <p className="text-[18px] font-[600]">Career Development</p>
                                            <p className="text-[16px]">Discover job opportunities , as well as talents well-tailored to your career needs and achieve career success on PORA.  
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            <div className="ease-in duration-500 col-span-1 rounded-[16px] p-3 cursor-pointer hover:shadow-[4px_8px_70px_rgba(0,0,0,0.04)] mt-4">
                                <div className="grid grid-cols-8 gap-5 ">
                                    <div className="col-span-1   "><img src={award} alt="support"/></div>
                                        <div className="col-span-7">
                                            <p className="text-[18px] font-[600]">Exclusive</p>
                                            <p className="text-[16px]">PORA is an exclusive social networking app where you can connect with fellow Africans in Diaspora who share similar interests for preserving and promoting African heritage 
  
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            <div className="ease-in duration-500 col-span-1 rounded-[16px] p-3 cursor-pointer hover:shadow-[4px_8px_70px_rgba(0,0,0,0.04)] mt-4">
                                <div className="grid grid-cols-8 gap-5">
                                    <div className="col-span-1"><img src={Safety} alt="support"/></div>
                                        <div className="col-span-7">
                                            <p className="text-[18px] font-[600]">Safety</p>
                                            <p className="text-[16px]">Your safety is our priority at PORA. We employ advanced security measures to protect your personal information and ensure a secure online environment. Feel confident and secure while engaging with fellow Africans in Diaspora.
  
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="col-span-1 hidden lg:block wow fadeInUp" data-wow-duration="3s">
                        <img src={UniqueImage} alt="Uniqueness"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unique

