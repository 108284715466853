import React,{useEffect, useState} from 'react'
import man from "../../images/privateman.svg"
import Logo from '../../images/logo.svg'
import axios from 'axios'

function OtpVerification() {
    useEffect(() => {
        // sessionStorage.clear()
        if(!sessionStorage.getItem('email')){
            window.location.href='/'
        }
    },[])
    const [otp, setOtp] = useState()
    const [error, setError] = useState()

    function verifyOtp(e){
        e.preventDefault();
        if(!otp){
            setError('Please input an otp')
        }else{
            let id = sessionStorage.getItem('identity')
            axios.get('https://backend.poraapp.com/api/v1/verify/private/otp?otp='+otp+'&identity='+id)
            .then((res) => {
                if(res.data.status == 'success'){
                    alert('Email verification successful')
                    sessionStorage.setItem('otp',otp)
                    window.location.href='/create-account'
                }else{
                    if(res.data.responseCode){
                        setError(res.data.responseMessage)
                    }else{
                        setError(res.data.message)
                    }
                    
                }
            }).catch((e)=>{
                setError('There was an error, please try again later')
            })
        }
    }
  return (
    <>
        <div className='grid grid-cols-2'>
            <div className='col-span-2 lg:col-span-1 grid grid-cols-1 pr-5'>
                <div className='col-span-1 pt-5 pl-5 lg:pl-20 flex flex-col space-y-10 pr-10'>
                    <img className='w-[215px]' src={Logo} />
                    <div className='flex flex-col space-y-5'>
                        <span className='text-[32px] lg:text-[42px] text-[#131313] font-[600] leading-[36px] leading-[58px] mt-5 lg:mt-20'>Email Verification</span>
                        <span className='text-[20px] text-[#333333] font-[400] leading-[150%]'>An OTP  has been sent to <span className='text-black'>{sessionStorage.getItem('email')}.</span> Please check and verify.</span>
                    </div>
                    <form onSubmit={(e) => verifyOtp(e)} className='flex flex-col space-y-4 lg:w-[70%]'>
                        <label>Code</label>
                        <input placeholder="Enter otp" onChange={(e) => setOtp(e.target.value)} className='border-[1px] py-4 px-3 border-[#E2E2E2] rounded-[4px]'/>
                        {error ? <span className='text-red-500'>{error}</span> : null}
                        <button className='bg-[#E1693E] w-[50%] text-white py-3 text-[20px] font-[700]'>Complete</button>
                    </form>
                </div>
            </div>
            <div className='col-span-1 hidden lg:block'>
                <img className='' src={man} />
            </div>
        </div>
    </>
  )
}

export default OtpVerification