import React, { useEffect, useState } from 'react'
import payment from "../../images/payment.svg"
import success from "../../images/success.svg"
import { PaystackButton } from 'react-paystack'
import axios from 'axios'

function PaymentBody() {
    let queryParameters = new URLSearchParams(window.location.search)
    useEffect(() => {
        if(!queryParameters.get("gn") || !queryParameters.get("identity")) window.location.href='/'
    },[])
    const [makePayment, setMakePayment] = useState()
    const [phone,setPhone] = useState()
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [error, setError] = useState()
    const [submitted, setSubmitted] = useState()
    const offerings = [
        {num:'01',title:'Exclusive Access',body:'By choosing Pora Matching, you gain access to a group of individuals who have also chosen to take a more private and discreet approach to Network.'},
        {num:'02',title:'Quality Matches',body:'Private Matching ensures matches with individuals who share your values, interests, culture and meet your specified criteria.'},
        {num:'03',title:'Saves Time and Effort',body:'Pora Matching saves you valuable time and effort that would otherwise be spent on searching for potential partners by yourself.'},
    ]
    let publicKey = process.env.REACT_APP_PUBLIC_KEY
    const [amount, setAmount] = useState();
    useEffect(() => {
        axios.get('https://backend.poraapp.com/api/v1/verify/private/amount?identity='+identity+'&type='+type,settings)
        .then((res) => {
            if(res.data.status == 'success'){
                setAmount((res.data.amount))
                setName(res.data.name)
                setEmail(res.data.email)
                //setSubmitted(1)
            }else{
                if(res.data.responseCode){
                    setError(res.data.responseMessage)
                }else{
                    setError(res.data.message)
                }
            }
        })
        .catch((e)=>{
            setError('Something went wrong please try again or contact us on support@poraapp.com')
        }) 
    },[])
      
    const componentProps = {
        email,
        amount,
        metadata: {
          name,
        },
        publicKey,
        text: "Pay Now",
        onSuccess: (res) =>{
            validatePayment(res.reference)
        }
      }
      let Myheaders = new Headers()
      Myheaders.append('Content-Type','application/json')
      let settings = {
          headers: { "Content-Type": "application/json" },
          method:'get'
      }
      let type = queryParameters.get("gn")
      let identity = queryParameters.get("identity")
      const validatePayment = (ref) => {
        axios.get('https://backend.poraapp.com/api/v1/verify/private/pay?reference='+ref+'&type='+type+'&identity='+identity,settings)
        .then((res) => {
            if(res.data.status == 'success'){
                setMakePayment(0)
                setSubmitted(1)
                console.log(res.data.message)
            }else{
                if(res.data.responseCode){
                    setError(res.data.responseMessage)
                }else{
                    setError(res.data.message)
                }
            }
        })
        .catch((e)=>{
            setError('Something went wrong please try again or contact us on support@poraapp.com')
        }) 
      }
  return (
    <>
        {submitted ? 
        <div className="transition-all ease-out duration-1000 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mt-5">
        <div className="relative mb-1 mt-1 mx-auto w-[90%] lg:w-[50%] rounded-[16px] mt-5">
        <div className="transition-all ease-out duration-1000 mt-5 border-0 rounded-[16px] shadow-lg relative flex flex-col w-full bg-white transition-all ease-out duration-1000 w-[100%] outline-none focus:outline-none items-center py-5 px-10 space-y-4">
            <div><img className='w-[100px] h-[100px]' src={success} /></div>
            <div className="mx-auto text-[#171717] text-[24px] font-[500] text-center">Your payment was successful</div>
            <div className="mx-auto my-3 mt-0 text-[#4F4F4F] text-[18px] font-[400] text-center">Thank you for choosing Pora Private Matching. We will reach out to you as soon as possible.</div>
            <div className="relative p-6 pt-0 flex-auto">
            <a href='/'
            className="mt-5 mx-auto text-center text-[18px] font-[700] text-white bg-[#E1693E] w-[165px] px-5 py-3 rounded outline-none mr-1 mb-1"
            type="submit">
            Done
            </a> 
            </div>
        </div>
        </div>
    </div> : null  
      }
    {makePayment ? 
        <div className="transition-all ease-out duration-1000 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mt-5">
        <div className="relative mb-1 mt-1 mx-auto w-[90%] lg:w-[50%] rounded-[16px] mt-5">
        <div className="transition-all ease-out duration-1000 mt-5 border-0 rounded-[16px] shadow-lg relative flex flex-col w-full bg-white transition-all ease-out duration-1000 w-[100%] outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5  ">
            <h3 className="text-3xl font=semibold"></h3>
            <button
                className="bg-transparent border-0 text-black float-right"
                onClick={() => setMakePayment(0)}
            >
                <span className="text-black h-7 w-7 block bg-[#F8F8F9]  rounded-full">
                x
                </span>
            </button>
            </div>
            <div className="mx-auto my-3 mt-0 text-[#4f4f4f] text-[18px] font-[300]">Kindly confirm your details</div>
            <div className="relative p-6 pt-0 flex-auto">
            <form className="px-8 w-full">
                <label className="block text-[#333333] mb-1">
                Full Name
                </label>
                <input type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="Enter your full name" />
                <label className="block text-[#333333] mb-1 mt-3">
                Email Address
                </label>
                <input type="email" onChange={(e) => setEmail(e.target.value)} defaultValue={email} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="example@gmail.com" />
                {/* <label className="block text-[#333333] mb-1 mt-3">
                Phone Number
                </label>
                <input type="text" required onChange={(e) => setPhone(e.target.value)} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="+1 647 937 227" /> */}
            </form>
            <div className='bg-[#E1693E] rounded-[8px] mx-8 text-[16px] font-[700] text-white py-2 h-[50px text-center mt-5'><PaystackButton {...componentProps} /></div>
            </div>
        </div>
        </div>
    </div> : null  
      }
        <div className='grid grid-cols-2 lg:mx-10 px-5 lg:px-8 mt-5 mb-5 gap-x-10'>
            <div className='col-span-2 lg:col-span-1 flex flex-col space-y-3'>
                <div className='flex flex-col'>
                    <p className='text-[#131313] text-[30px] lg:text-[40px] font-[600]'>Make Payment</p>
                    <p className='text-[#333333] text-[16px] lg:text-[20px] font-[400]'>Please proceed to make payment.</p>
                    <div className='lg:hidden mt-4'><button onClick={() => setMakePayment(1)} className='bg-[#E1693E] rounded-[8px] text-[16px] font-[700] text-white py-2 w-full h-[50px]'>Proceed to Make Payment</button></div>
                </div>
                <div className='flex flex-col space-y-2'>
                    {offerings.map(({num,title,body,bg},index) => {
                        return (
                            <div key={index} className='border-[1px] rounded-[8px] flex flex-col space-y-3 py-3 px-5'>
                                <div className='flex flex-row space-x-3 items-center'>
                                    <div className={`rounded-full w-[40px] h-[40px] ${num == '01' ? 'bg-[#F6F5FD]' : num == '02' ? 'bg-[#FFF1ED]' : 'bg-[#FFF7EC]'}`}><p className='text-[#071440] text-[28px] font-[700] ml-[-9px]'>{num}</p></div>
                                    <p className='text-[#000000] text-[24px] font-[500]'>{title}</p>
                                </div>
                                <p className='text-[#4F4F4F] font-[400] text-[16px] ml-[-9px]'>{body}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='hidden lg:block'><button onClick={() => setMakePayment(1)} className='bg-[#E1693E] rounded-[8px] text-[16px] font-[700] text-white py-2 w-full h-[50px]'>Proceed to Make Payment</button></div>
                
            </div>
            <div className='hidden lg:block'><img src={payment} /></div>
        </div>
    </>
  )
}

export default PaymentBody