import React, { Fragment, useEffect, useState } from 'react'
import Logo from '../../images/logo.svg'
import styled from 'styled-components'
import { Link,useLocation } from 'react-router-dom'
import axios from 'axios';
const Header = () => {

  const join = () =>{
    if (window.navigator.platform.indexOf("Win") != -1 || window.navigator.platform.indexOf("Android") != -1) {
        window.location.href='https://play.google.com/store/apps/details?id=com.getpora.android.app'
    } else if (window.navigator.platform.indexOf("Mac") != -1 || window.navigator.platform.indexOf("iPhone") != -1 || window.navigator.platform.indexOf("iPad") != -1 || window.navigator.platform.indexOf("iPod") != -1) {
        window.location.href='https://www.apple.com/app-store/'
    } else {
        window.location.href='https://play.google.com/store/apps/details?id=com.getpora.android.app' 
    }
}

  const HeaderNav = styled.div`
    .oldnav{
      color:yellow!important;
      transition: all 0.3s ease-in-out;
    }
    .nav{
      display:block!important;
    }
  `
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true)

  var [body, setBody] = useState({})
  const [register, setRegister] = useState(0)
  let page = useLocation().pathname;
  useEffect(() => {
    // const timer  = setTimeout(() => {page == '/' ? setRegister(1) : setRegister(0)},3000)
    // return () => clearTimeout(timer);
  }, []);
  const [showModal, setShowModal] = useState(false);
  var MyHeaders = new Headers();
  MyHeaders.append("Accept", "application/json");
  MyHeaders.append("Content-Type", "application/json");
  var settings = {
      headers: MyHeaders
  };
  const newInput = (e,field) =>{
    setBody({...body,
    [field] : e.target.value
    })
}
const submit = (e) => {
    e.preventDefault()
    if(continues) return setContinues(false)
    PopulateList(body)
}

const [checkPass, setCheckPass] = useState()
const [choose, setChoose] = useState()
const [processing, setProcessing] = useState()
const [continues, setContinues] = useState(1)

function PopulateList(props) {
    let datas = {"vDeviceType":3,"vFirstName":props.first_name,"vLastName":props.last_name,"vEmailId":props.email,"vPassword":props.password,"vPassword_confirmation":props.confirm_password,"vMobileNumber":props.phone,'device':props.device}
    if(!props.device || props.device == 'null') {
      setChoose(1)
      return
    }else{
      setChoose(0)
    }
    if(props.password != props.confirm_password){
      setCheckPass(1)
      return
    }else{
      setCheckPass(0)
    }
    setProcessing(1)
    //setBody(datas)
    const Base = "/api/v1/ios_waitlist"
    axios.post("https://backend.poraapp.com/api/v1/oauth/signup", datas, settings)
    .then((res) => {
        if(res.data.responseCode == 200){
            if(res.data.code == 1){
              alert(res.data.responseMessage)
              setRegister(0)
            }else{
              alert(res.data.responseMessage)
              window.location.href="https://play.google.com/store/apps/details?id=com.getpora.android.app"
            }
            setContinues(1)
        }else{
            alert(res.data.responseMessage)
            //window.location.href=''
        }
        setProcessing(0)
    })
    .catch((e)=>{
        alert('Something went wrong please try again')
        //window.location.href=''
    })  
    return
}

  const controlHeader = () => {
    if (window.scrollY > 20) {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', controlHeader)
    return () => {
      window.removeEventListener('scroll', controlHeader)
    }
  }, [])
  const proceed = () => {
    
  }
  return (
    <Fragment>
              {register ? 
                    <div className="transition-all ease-out duration-1000 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mt-5">
                    <div className="relative mb-1 mt-1 mx-auto w-[90%] lg:w-[50%] rounded-[16px] mt-5">
                    <div className="transition-all ease-out duration-1000 mt-5 border-0 rounded-[16px] shadow-lg relative flex flex-col w-full bg-white transition-all ease-out duration-1000 w-[100%] outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5  ">
                        <h3 className="text-3xl font=semibold"></h3>
                        <button
                            className="bg-transparent border-0 text-black float-right"
                            onClick={() => setRegister(0)}
                        >
                            <span className="text-black h-7 w-7 block bg-[#F8F8F9]  rounded-full">
                            x
                            </span>
                        </button>
                        </div>
                        <div className="mx-auto text-[#171717] text-[28px] font-[500]">Join Pora now</div>
                        <div className="mx-auto my-3 mt-0 text-[#4f4f4f] text-[18px] font-[300]">Enjoy 30 days of Free Elite Privileges</div>
                        <div className="relative p-6 pt-0 flex-auto">
                        <form className="px-8 w-full" onSubmit={submit}>
                            <label className="block text-[#333333] mb-1">
                            First Name
                            </label>
                            <input type="text" defaultValue={body.first_name} required onChange={(e) => newInput(e, "first_name")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="Enter your first name" />
                            <label className="block text-[#333333] mb-1 mt-3">
                            Last Name
                            </label>
                            <input type="text" required onChange={(e) => newInput(e, "last_name")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="Enter your last name" />
                            <label className="block text-[#333333] mb-1 mt-3">
                            Email Address
                            </label>
                            <input type="email" required onChange={(e) => newInput(e, "email")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="example@gmail.com" />
                            <label className="block text-[#333333] mb-1 mt-3">
                            Phone Number
                            </label>
                            <input type="text" required onChange={(e) => newInput(e, "phone")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="+1 647 937 227" />
                            <label className="block text-[#333333] mb-1 mt-3">
                            Device Type
                            </label>
                            <select required onChange={(e) => newInput(e, "device")} className="mb-2 shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="example@gmail.com">
                              <option value="null">Choose Device</option>
                              <option value="android">Android</option>
                              <option value="ios">iOS</option>
                            </select>
                            {choose ? <><span className='text-[14px] text-[#FF0000]'>Please choose a device type </span><br/></> : null }
                            {!continues ?
                            <>
                            <span className='font-[600]'>Secure your account</span>
                            <label className="block text-[#333333] mb-1 mt-2">
                            Password
                            </label>
                            <input type="password" required onChange={(e) => newInput(e, "password")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]]" placeholder="password"/>
                            <label className="block text-[#333333] mb-1 mt-3">
                            Confirm Password
                            </label>
                            <input type="password" required onChange={(e) => newInput(e, "confirm_password")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]]" placeholder="password"/>
                            </>:null}
                            {checkPass ? <span className='text-[14px] text-[#FF0000]'>Please check your passwords </span> : null }
                            
                            {processing ? 
                            <button
                            disabled
                            className="mt-5 text-white w-full bg-[#E1693E] active:bg-yellow-700 font-bold uppercase text-sm px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="submit">
                            Processing ...
                            </button>
                             : 
                             <>
                             {continues ? 
                             <button onClick={()=>setContinues(1)}
                             className="mt-5 text-white w-full bg-[#E1693E] active:bg-yellow-700 font-bold uppercase text-sm px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                             type="submit">
                             Continue
                             </button> :
                             <button
                             className="mt-5 text-white w-full bg-[#E1693E] active:bg-yellow-700 font-bold uppercase text-sm px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                             type="submit">
                             Get Started
                             </button>
                              }
                             </>
                            }
                        </form>
                        </div>
                    </div>
                    </div>
                </div> : null  
      }
      <HeaderNav className="pb-[81px]">
        <nav className={`wow fadeIn overflow-x-hidden oldnav transition-all ease-out duration-1000 left-0 right-0 Inter flex flex-wrap items-center justify-between px-2 py-1 bg-white lg:bg-white mb-3 fixed hidden ${showNavbar && 'nav'}`}>
          <div className="container px-2 lg:px-4 mx-auto flex flex-wrap items-center justify-between transition-all ease-out duration-1000 font-Inter">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start lg:ml-10 xl:ml-8">
              <a
                className="text-sm lg:ml-4 text-[20px] font-[700] leading-relaxed inline-block mr-4 py-2 whitespace-nowrap text-[#E1693E]"
                href="/">
                <span><img src={Logo} alt="Pora"/></span>
              </a>

              <button
                className="flex items-center mt-5 px-3 h-[30px] py-2 border rounded text-[#CCE0FF] border-[#CCE0FF] lg:hidden hover:text-white hover:bg-[#CCE0FF] hover:border-[#CCE0FF]"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center transition-all lg:mt-[-75px] ease-in duration-1000 lg:ml-[-45px] xl:ml-[110px]" +
                (navbarOpen ? " flex" : " hidden")
              }
              id="example-navbar-danger"
            >
              <ul className="transition-all ease-in duration-500 text-[#333333] flex mx-auto flex-col lg:flex-row list-none lg:ml-[160px] xl:ml-[260px] lg:mx-auto">
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug hover:opacity-75"
                    href="/#about"
                  >
                   <span className="ml-2 font-normal text-[16px]">About Us</span>
                  </a>
                </li>
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug hover:opacity-75"
                    href="/#features"
                  >
                    <span className="ml-2 font-normal text-[16px]">Features</span>
                  </a>
                </li>
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug hover:opacity-75"
                    href="/elite"
                  >
                    <span className="ml-2 font-normal text-[16px]">Pora Elite</span>
                  </a>
                </li>
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug hover:opacity-75"
                    href="/private-matching"
                  >
                   <span className="ml-2 font-normal text-[16px]">Private Matching</span>
                  </a>
                </li>
                <li className="nav-item mx-auto">
                  <Link to ="/blog"
                    className="px-3 py-2 flex items-center text-xs leading-snug hover:opacity-75"
                  >
                   <span className="ml-2 font-normal text-[16px]">Blog</span>
                  </Link>
                </li>
                <li className='mx-auto'>
                  <ul className="flex flex-col lg:flex-row list-none">
                    <li className="nav-item mx-auto lg:ml-10">
                      <a
                        target="blank" className="lg:px-3 flex items-center text-xs leading-snug my-auto text-white hover:opacity-75"
                        
                      >
                        <button onClick={join} className="transition-all text-white ease-in duration-500 hover:text-[#333333] hover:border-[1px] hover:bg-white hover:border-[#E1693E] rounded-[8px] bg-[#E1693E] w-[200px] mb-5 lg:mb-0 lg:w-[180px] lg:h-[40px] pt-1 my-auto px-auto pb-2 pr-2 grid text-center pl-2">
                          <span className="ml-2 font-[500] text-[16px] mx-auto my-auto font-Inter my-auto mx-auto">Download Pora Now</span>
                        </button>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <hr></hr>
      </HeaderNav>
      <hr></hr>
    </Fragment>
  )
}

export default Header