import React from "react";
import Header from "../header";
import PrivateMatchingBody from "./homebody"
import Footer from "../footer/footer1"

const Home = () => {
   return ( 
        <div className="font-Inter">
         <div behavior="alternate" className="bg-[#E1693E] text-white"><marquee>For any technical issues, please contact us @ support@poraapp.com</marquee></div>
         <Header></Header>
         <PrivateMatchingBody></PrivateMatchingBody>
         <Footer></Footer>
        </div>
   )
}

export default Home