import React,{useState,useEffect} from 'react'
import select from "../../images/selectimage.svg"
import success from "../../images/success.svg"
import empty from "../../images/empty.svg"
import { Hearts } from 'react-loader-spinner'
import axios from 'axios'

function PrivateForm() {
    useEffect(() => {
        if(!sessionStorage.getItem('email')){
            window.location.href='/'
        }
    },[])
    const [firstImage, setFirstImage] = useState(null)
    const [secondImage, setSecondImage] = useState(null)
    const [submitted, setSubmitted] = useState()
    const [processing, setProcessing] = useState()
    const [error, setError] = useState()
    let ori = window.location.origin

    function uploadImage(){
        if(document.getElementById('first').src == ori+empty || document.getElementById('second').src == ori+empty){
            document.getElementById('image2').click()
            //setError(null)
        }else{
            alert('You have already selected two images')
        }
        
    }

    function setUploadedImage(e,field){
        if(document.getElementById('first').src == ori+empty){
            document.getElementById('first').src=URL.createObjectURL(e.target.files[0])
            document.getElementById('firstRemove').style.display="block"
            createRecord(e,'image1')
        }else{
            document.getElementById('second').src=URL.createObjectURL(e.target.files[0])
            document.getElementById('secondRemove').style.display="block"
            createRecord(e,'image2')
        }
        
    }
    function removeImage(num){
        if(num == 1){
            document.getElementById('first').src=empty
            document.getElementById('firstRemove').style.display="none"
            formData.delete('image1')
        }else{
            document.getElementById('second').src=empty
            document.getElementById('secondRemove').style.display="none" 
            formData.delete('image2')
        }
    }
    const formData = new FormData();
    formData.append('otp', sessionStorage.getItem('otp'))
    formData.append('resident_country', 'Nigeria')
    formData.append('faith', 'Christianity')
    formData.append('nationality', 'Nigerian')
    formData.append('email', sessionStorage.getItem('email'))

    function createRecord(e,field){
        if(field == 'image1' || field == 'image2'){
            //alert(field)
            formData.set(field, e.target.files[0])
        }else{
            formData.set(field, e.target.value)
        }
    }
    let Myheaders = new Headers()
    Myheaders.append('Content-Type','multipart/form-data')
    let settings = {
        headers: { "Content-Type": "multipart/form-data" },
        method:'post'
    }
    function submitForm(e){
        e.preventDefault()
        setProcessing(1)
        let datas = formData
        axios.post('https://backend.poraapp.com/api/v1/private/match',datas,settings)
        .then((res) => {
            if(res.data.status == 'success'){
                console.log(res.data.message)
                setSubmitted(1)
                sessionStorage.clear()
            }else{
                if(res.data.responseCode){
                    setError(res.data.responseMessage)
                }else{
                    setError(res.data.message)
                }
                setProcessing(0)
                setSubmitted(0)
            }

        })
        .catch((e)=>{
            alert('Something went wrong please try again')
            setProcessing(0)
            //setSubmitted(0)
            //window.location.href=''
        }) 
        //setSubmitted(1)
    }
  return (
    <>
    {submitted ? 
        <div className="transition-all ease-out duration-1000 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mt-5">
        <div className="relative mb-1 mt-1 mx-auto w-[90%] lg:w-[50%] rounded-[16px] mt-5">
        <div className="transition-all ease-out duration-1000 mt-5 border-0 rounded-[16px] shadow-lg relative flex flex-col w-full bg-white transition-all ease-out duration-1000 w-[100%] outline-none focus:outline-none items-center py-5 px-10 space-y-4">
            <div><img className='w-[100px] h-[100px]' src={success} /></div>
            <div className="mx-auto text-[#171717] text-[24px] font-[500] text-center">Form submitted successfully</div>
            <div className="mx-auto my-3 mt-0 text-[#4F4F4F] text-[18px] font-[400] text-center">Thank you for submitting the form. Our team will review your information and get back to you shortly. </div>
            <div className="relative p-6 pt-0 flex-auto">
            <a href='/private-matching'
            className="mt-5 mx-auto text-center text-[18px] font-[700] text-white bg-[#E1693E] w-[165px] px-5 py-3 rounded outline-none mr-1 mb-1"
            type="submit">
            Back to Private Matching
            </a> 
            </div>
        </div>
        </div>
    </div> : null  
      }
        <form onSubmit={submitForm} className={`flex flex-col ${submitted ? 'opacity-25' : ''}`}>
            <div className='grid grid-cols-8 mb-5'>
                <div className='col-span-8 lg:col-span-3 flex flex-col'>
                    <span className='text-[#828282] text-[24px]'>01</span>
                    <span className='text-[#000000] text-[34px]'>Personal Information</span>
                </div>
                <div className='col-span-8 lg:col-span-5 grid grid-cols-2 gap-x-5 gap-y-4 mt-5 lg:mt-auto'>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>First Name</label>
                        <input required onChange={(e) => createRecord(e,'first_name')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your first name' />
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Last Name</label>
                        <input required onChange={(e) => createRecord(e,'last_name')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your last name' />
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Date of Birth</label>
                        <input required onChange={(e) => createRecord(e,'dob')} type='date' className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your date of birth' />
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Email Address</label>
                        <input required onChange={(e) => createRecord(e,'email')} readOnly defaultValue={sessionStorage.getItem('email')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your email' />
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Nationality</label>
                        <input required onChange={(e) => createRecord(e,'nationality')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your nationality' />
                        {/* <select required onChange={(e) => createRecord(e,'nationality')} className='border-[1px] border-[#E2E2E2] p-3 outline-none h-[50px]'>
                            <option>Nigerian</option>
                            <option>United Kingdom</option>
                            <option>Canada</option>
                        </select> */}
                    </div>
                    <div className='col-span-2 lg:col-span-1 '>
                        <div className='grid grid-cols-8 space-x-2'>
                            <div className='col-span-2 flex flex-col space-y-2'>
                                <label className='text-[#131313] font-[400] text-[18px]'>Area code</label>
                                <input required onChange={(e) => createRecord(e,'code')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Code' />
                            </div>
                            <div className='col-span-6 flex flex-col space-y-2'>
                                <label className='text-[#131313] font-[400] text-[18px]'>Telephone</label>
                                <input required onChange={(e) => createRecord(e,'phone')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter telephone number' />
                            </div>
                        </div>

                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Faith</label>
                        <input required onChange={(e) => createRecord(e,'faith')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter religion' />
                        {/* <select required onChange={(e) => createRecord(e,'faith')} className='border-[1px] border-[#E2E2E2] p-3 outline-none h-[50px]'>
                            <option value="christianity">Christianity</option>
                            <option value="islamic">Islamic</option>
                        </select> */}
                    </div>
                </div>
            </div>
            <hr className='mt-10'/>
            <div className='grid grid-cols-8 mb-5 mt-10 lg:mt-20'>
                <div className='col-span-8 lg:col-span-3 flex flex-col'>
                    <span className='text-[#828282] text-[24px]'>02</span>
                    <span className='text-[#000000] text-[34px]'>Address</span>
                </div>
                <div className='col-span-8 lg:col-span-5 grid grid-cols-2 gap-x-5 gap-y-4 mt-5 lg:mt-auto'>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Country of Residence</label>
                        <input required onChange={(e) => createRecord(e,'resident_country')}  className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your country of residence' />
                        {/* <select required onChange={(e) => createRecord(e,'resident_country')} className='border-[1px] border-[#E2E2E2] p-3 outline-none h-[50px]'>
                            <option>Nigeria</option>
                            <option>United Kingdom</option>
                            <option>Canada</option>
                        </select> */}
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>City of Residence</label>
                        <input required onChange={(e) => createRecord(e,'resident_city')}  className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your city of residence' />
                    </div>
                </div>
            </div>
            <hr className='mt-10'/>
            <div className='grid grid-cols-8 mb-5 mt-10 lg:mt-20'>
                <div className='col-span-8 lg:col-span-3 flex flex-col'>
                    <span className='text-[#828282] text-[24px]'>03</span>
                    <span className='text-[#000000] text-[34px]'>Education</span>
                </div>
                <div className='col-span-8 lg:col-span-5 grid grid-cols-2 gap-x-5 gap-y-4 mt-5 lg:mt-auto'>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Secondary school(s) attended</label>
                        <input required onChange={(e) => createRecord(e,'high_school')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your secondary school' />
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>University & Degree</label>
                        <input required accept="image/png, image/jpg, image/jpeg" onChange={(e) => createRecord(e,'university')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter university attended and degree.' />
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Postgraduate degree (if any)</label>
                        <input accept="image/png, image/jpg, image/jpeg" onChange={(e) => createRecord(e,'postgraduate')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter post graduate degree.' />
                    </div>
                </div>
            </div>
            <hr className='mt-10'/>
            <div className='grid grid-cols-8 mb-5 mt-10 lg:mt-20'>
                <div className='col-span-8 lg:col-span-3 flex flex-col'>
                    <span className='text-[#828282] text-[24px]'>04</span>
                    <span className='text-[#000000] text-[34px]'>Occupation</span>
                </div>
                <div className='col-span-8 lg:col-span-5 grid grid-cols-2 gap-x-5 gap-y-4 mt-5 lg:mt-auto'>
                    <div className='col-span-2 lg:col-span-2 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>What is your occupation ?</label>
                        <input required onChange={(e) => createRecord(e,'occupation')} className='border-[1px] border-[#E2E2E2] p-3 outline-none' placeholder='Enter your occupation' />
                    </div>
                    <div className='col-span-2 lg:col-span-2 flex flex-col space-y-2'>
                        <label className='text-[#131313] font-[400] text-[18px]'>Briefly describe the nature of your work.</label>
                        <textarea required onChange={(e) => createRecord(e,'nature_of_work')} className='border-[1px] border-[#E2E2E2] p-3 outline-none h-[133px]' placeholder='Enter nature of work.'></textarea>
                    </div>
                </div>
            </div>
            <hr className='mt-10'/>
            <div className='grid grid-cols-8 mb-5 mt-10 lg:mt-20'>
                <div className='col-span-8 lg:col-span-3 flex flex-col'>
                    <span className='text-[#828282] text-[24px]'>04</span>
                    <span className='text-[#000000] text-[34px]'>Photo Upload</span>
                </div>
                <div className='col-span-8 lg:col-span-5 grid grid-cols-2 gap-x-5 gap-y-4 mt-5 lg:mt-auto'>
                    <div className='col-span-2 flex flex-col space-y-2'>
                        
                        <>
                        <label className='text-[#131313] font-[400] text-[18px]'>Upload two of your images and ensure that the uploaded images are clear.</label>
                        <div className='flex flex-col border-[1px] border-dashed rounded-[8px] items-center py-7 space-y-2'>
                            <img className='w-[48px] h-[48px]' src={select} />
                            <span>Select your image(5mb Max) from <span onClick={() => uploadImage()} className='text-[#E1693E] cursor-pointer'>here</span></span>
                            <span className='text-[#828282]'>JPEG or JPG</span>
                            <input id="image2" onChange={(e) => setUploadedImage(e,'image2')} hidden type='file'/>
                            <input id="image1" onChange={(e) => setUploadedImage(e,'image1')} hidden type='file'/>
                        </div>
                        </>
                        {/* {firstImage || secondImage ? */}
                            <div className='grid grid-cols-2 gap-x-3'>
                            
                            {/* {firstImage ? */}
                            <>
                            <div className=' relative'>
                            <img id="first" className='col-span-1 w-full h-[200px]' src={empty}/>
                            <span onClick={() => removeImage(1)} title='remove' id="firstRemove" className='bg-white hidden p-3 w-[32px] h-[32px] cursor-pointer rounded-full absolute top-3 right-3 items-center py-2 text-[20px]'><p className="mt-[-10px]">x</p></span>
                            </div> 
                            </>
                            {/* : null } 
                            { */}
                                
                                <div className='relative'>
                                <img id="second" className='col-span-1 w-full h-[200px]' src={empty}/>
                                <span onClick={() => removeImage(2)} title='remove' id="secondRemove" className='bg-white hidden p-3 w-[32px] h-[32px] cursor-pointer rounded-full absolute top-3 right-3 items-center py-2 text-[20px]'><p className="mt-[-10px]">x</p></span>
                                </div>
                                {/* : null
                            } */}
                        </div>
                        {/* : null} */}
                    </div>
                </div>
            </div>
            <div className='grid mt-4'>
                <div className='text-right grid'>
                    {processing ? 
                    <div className='ml-auto'><Hearts height="80" width="80" color="#E1693E" ariaLabel="hearts-loading" wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />...Processing</div>
                    : <button type='submit' className='bg-[#E1693E] ml-auto text-white lg:w-[20%] rounded-[8px] p-3 px-9 font-[700] text-[20px]'>Submit form</button>
                    }
                    {error ? <span className='text-start text-red-500 text-[14px]'>{error}</span> : null}
                </div>
            </div>
        </form>
    </>
  )
}

export default PrivateForm