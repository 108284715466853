import React from "react";

const Hero = () => {
    return (
        <div className="grid grid-cols-1 bg-[#FFF9F7] h-[200px] lg:h-[415px] py-auto my-auto">
            <div className="text-center col-span-1 mx-auto my-auto"><p className="font-[700] text-[40px] lg:text-[72px]">Our Blog</p></div>
        </div>
    )
}

export default Hero