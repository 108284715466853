import React, {useState}  from "react"
import GooglePlay from '../../images/googleplay.svg'
import AppStore from '../../images/appstore.svg'
import HeroImage from '../../images/HeroImage.svg'
import Applestore from '../../images/new app store.jpeg'
import Logo from '../../images/logo2.png'
import axios from 'axios';
import './hero.scss'
const Hero = () => {
    var [body, setBody] = useState()
    const [backResponse,setBackResponse] = useState(null)
    var MyHeaders = new Headers();
    MyHeaders.append("Accept", "application/json");
    MyHeaders.append("Content-Type", "application/json");
    var settings = {
        headers: MyHeaders
    };
    function PopulateList(props) {
        let datas = {"name":props.name,"email":props.email,"phone_number":props.phone_number}
        setBody(datas)
        const Base = "/api/v1/ios_waitlist"
        axios.post("https://backend.poraapp.com/api/v1/ios_waitlist", datas, settings)
        .then((res) => {
            if(res.data.message){
                alert(res.data.message)
                window.location.href=''
            }else{
                alert(res.data.responseMessage)
            }
        })
        .catch((e)=>{
            alert(e.response.data.message)
            window.location.href=''
        })  
        return null
    }

    const [showModal, setShowModal] = useState(false);
    const newInput = (e,field) =>{
        setBody({...body,
        [field] : e.target.value
        })
    }
    const submit = () => {
        PopulateList(body)
        setShowModal(false)
    }

    return (
        <>
        <div class="elfsight-app-d296bd18-f85f-42f6-91b2-7b8f632390d9"></div>
            <div className="grid grid-cols-2 p-5 lg:p-20 lg:mt-[-30px] bg-[#FFFAF8]">
                <div className="col-span-2 lg:col-span-1 my-auto">
                    <div className="grid grid-cols-1">
                        <div className="col-span-1 hidden lg:block text-[#000000] font-[700] text-[28px] leading-[120%] lg:text-[57px] lg:leading-[120%]">
                        <p>
                            <span data-wow-duration="2s" className="wow fadeInLeft"> <span className="text-[#E1693E]"> #1  Social and </span>  Professional   <span className="text-[#E1693E]"> Networking App For</span>  Africans <span className="text-[#E1693E]"> In Diaspora</span>  </span>
                        </p>
                        </div>
                        <div className="col-span-1 lg:hidden text-[#000000] font-[700] text-[28px] leading-[120%] lg:text-[58px] lg:leading-[120%]">
                        <p>
                            <span data-wow-duration="2s" className="wow fadeInLeft"> <span className="text-[#E1693E]"> #1  Social and </span>  Professional   <span className="text-[#E1693E]"> Networking App For</span>  Africans <span className="text-[#E1693E]"> In Diaspora</span>  </span>
                        </p>

                        </div>
                        <div className="col-span-1 mt-5 wow fadeInLeft text-[#4F4F4F]" data-wow-duration="2s" data-wow-delay=".5s">
                            <p>
                            At Pora, we're passionate about connecting Africans in diaspora, celebrating African culture, values, and ethnicity. Join us to celebrate your heritage and discover meaningful connections that resonate with your roots.
                            </p>
                        </div>
                        <div className="col-span-1 mt-5 wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".5s">
                            <div className="grid grid-cols-3 gap-5 lg:gap-0">
                                <div className="col-span-1">
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.getpora.android.app"><img src={GooglePlay} alt="Google Play" width={200} /></a>
                                </div>
                                <div className="col-span-1 lg:mt-[-0px]">
                                    <a href="https://apps.apple.com/ng/app/pora-african-social-networking/id6446169785" target="_blank" rel="noopener noreferrer">
                                        <img src={Applestore} alt="Pora App" width={170} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 text-white mt-5 wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".5s">
                            {/* <button onClick={() => setShowModal(true)} className="bg-[#E1693E] rounded-[8px] w-[200px] h-[50px] lg:w-[233px] lg:h-[54px] ease-in duration-500 hover:border-[1px] hover:bg-white hover:border-[#E1693E] hover:text-[#333333]">Join waitlist for IOS</button> */}
                            {/* <>

        {showModal ? (
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-[90%] lg:w-[50%] rounded-[16px]">
            <div className="border-0 rounded-[16px] shadow-lg relative flex flex-col w-full bg-white transition-all ease-out duration-1000 w-[100%] outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5  ">
                <h3 className="text-3xl font=semibold"></h3>
                <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowModal(false)}
                >
                    <span className="text-black h-7 w-7 block bg-[#F8F8F9]  rounded-full">
                    x
                    </span>
                </button>
                </div>
                <div className="mx-auto"><img src={Logo}/></div>
                <div className="mx-auto mt-5 text-[#171717] text-[32px] font-[600]">Join waitlist for iOS</div>
                <div className="relative p-6 flex-auto">
                <form className="px-8 pt-6 pb-8 w-full">
                    <label className="block text-[#333333] mb-1">
                    Name
                    </label>
                    <input required onChange={(e) => newInput(e, "name")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="Enter your name" />
                    <label className="block text-[#333333] mb-1 mt-5">
                    Email Address
                    </label>
                    <input required onChange={(e) => newInput(e, "email")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="example@gmail.com" />
                    <label className="block text-[#333333] mb-1 mt-5">
                    Phone Number
                    </label>
                    <input required onChange={(e) => newInput(e, "phone_number")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]]" placeholder="09088865324"/>
                    <button
                    className="mt-9 text-white w-full bg-[#E1693E] active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={submit}
                >
                    Submit to Join Waitlist
                </button>
                </form>
                </div>
            </div>
            </div>
        </div>
    ) : null}
    </> */}
                        </div>
                    </div>
                </div>
                <div className="heroimage col-span-2 lg:col-span-1 mt-5 lg:mt-0 wow fadeInRight">

                    <img src={HeroImage} alt="Social App"/>
                    
                </div>
            </div>
        </>
    )
}

export default Hero
