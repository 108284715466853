import React,{useState} from "react";
import Fb from "../../images/fb.svg"
import Lk from "../../images/lk.svg"
import Ig from "../../images/ig.svg"
import Twi from "../../images/twitter.svg"
import Mail from '../../images/mail.svg'
import Phone from '../../images/phone.svg'
import Address from '../../images/address.svg'
import FooterGooglePlay from '../../images/footer-google-play.svg'
import FooterAppStore from '../../images/footer-app-store.svg'
import AppleStore from '../../images/applestore.svg'
import Logo from "../../images/footer-logo.svg"
import axios from 'axios';

const FooterOne = () => {
    var [body, setBody] = useState()
    const [backResponse,setBackResponse] = useState(null)
    var MyHeaders = new Headers();
    MyHeaders.append("Accept", "application/json");
    MyHeaders.append("Content-Type", "application/json");
    var settings = {
        method: 'post',
        headers: MyHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
    };
    function PopulateList(props) {
        let datas = {"name":props.name,"email":props.email,"phone_number":props.phone_number}
        setBody(datas)
        axios.post("https://backend.poraapp.com/api/v1/ios_waitlist", datas, settings)
        .then((res) => {
            if(res.data.message){
                alert(res.data.message)
                window.location.href=''
            }else{
                alert(res.data.responseMessage)
            }
        })
        .catch((e)=>{
            alert(e.response.data.message)
            window.location.href=''
        })  
        return null
    }
    const newInput = (e,field) =>{
        setBody({...body,
        [field] : e.target.value
        })
    }
    const submit = () => {
        PopulateList(body)
        setShowModal(false)
    }

    const [showModal, setShowModal] = useState(false);

    return (
        <div className="grid grid-cols-8 bg-black text-white pl-5 pr-5 lg:pr-20 lg:pl-20 pt-4 pb-4">
        <div className="col-span-8 lg:col-span-2 p-2 lg:p-0">
            <div className="grid grid-cols-1">
                <div className="col-span-1"><img src={Logo} alt="Logo" /></div>
            </div>
            <div className="grid grid-cols-1 mt-8">
                © 2023 Pora. All rights reserved
            </div>
            <div className="grid grid-cols-1 mt-3">
                <ul className="">
                    <li className="inline-block pr-5 pt-2"><a href="https://instagram.com/getpora"><img src={Ig} alt="Instagram"/></a></li>
                    <li className="inline-block pr-5 pt-2"><a href="https://facebook.com/getpora"><img src={Fb} alt="Facebook"/></a></li>
                    <li className="inline-block pr-5 pt-2"><a href="https://linkedin.com"><img src={Lk} alt="LinkedIn"/></a></li>
                    <li className="inline-block pr-5 pt-2"><a href="https://twitter.com"><img src={Twi} alt="Twitter"/></a></li>
                </ul>
            </div>
            
        </div>
        <div className="col-span-8 lg:col-span-2 lg:mx-auto">
            <ul className="">
                <li className="p-2 text-[20px] font-[600]">Company</li>
                <li className="p-2 lg:mt-5"><a href="/terms">Terms & Conditions</a></li>
                <li className="p-2"><a href="/privacy">Privacy Policy</a></li>
                <li className="p-2"><a href="/elite">Pora Elite</a></li>
                <li className="p-2"><a href="/private-matching">Private Matching</a></li>
                {/* <li className="p-2"><a href="/#faqs">FAQs</a></li> */}
            </ul>
        </div>
        <div className="col-span-8 lg:col-span-2 lg:mx-auto">
            <ul className="">
                <li className="p-2 text-[20px] font-[600]">Contact Us</li>
                <li className="p-2 lg:mt-5">
                    <span className="grid grid-cols-8">
                        <span className="col-span-1 my-auto text-center"><img src={Mail} alt="info@poraapp.com"/></span>
                        <span className="col-span-6 my-auto pl-2">info@poraapp.com</span>
                    </span>
                </li>
                <li className="p-2">
                    <span className="grid grid-cols-8">
                        <span className="col-span-1 my-auto text-center"><img className="" src={Phone} alt="info@poraapp.com"/></span>
                        <span className="col-span-6 my-auto pl-2">
                            ±1 416 417 3697 - Toronto<br/>
                            (WhatsApp only)
                        </span>
                        <span className="col-span-6 pl-10">
                            
                        </span>
                    </span>
                </li>
                <li className="p-2">
                <span className="grid grid-cols-8">
                        <span className="col-span-1 my-auto text-center"><img src={Address} alt="info@poraapp.com"/></span>
                        <span className="col-span-6 my-auto pl-2">
                            Pora lnc<br/>
                            20 Camden Street<br/>
                            Toronto, ON M5V 1V<br/>
                            Canada
                        </span>
                        {/* <span className="col-span-6 my-auto pl-10">
                            20 Camden Street<br/>
                            Toronto, ON M5V 1V<br/>
                            Canada
                        </span> */}
                    </span>
                </li>
            </ul>
        </div>
        <div className="col-span-8 lg:col-span-2 lg:mx-auto">
            <ul className="">
                <li className="p-2 text-[20px] font-[600]">Get The App</li>
                <li className="p-2 lg:mt-5"><a href="https://play.google.com/store/apps/details?id=com.getpora.android.app"><img src={FooterGooglePlay} alt="Pora App" /></a></li>
                <li className="p-2">
                    <a href="https://apps.apple.com/ng/app/pora-african-social-networking/id6446169785" target="_blank" rel="noopener noreferrer">
                        <img src={AppleStore} alt="Pora App" />
                    </a>
                </li>

            </ul>
        </div>

        <div className="col-span-1 mt-5">
                        <>
                        {showModal ? (
                            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto w-[90%] lg:w-[50%] rounded-[16px]">
                            <div className="border-0 rounded-[16px] shadow-lg relative flex flex-col w-full bg-white transition-all ease-out duration-1000 w-[100%] outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5  ">
                                <h3 className="text-3xl font=semibold"></h3>
                                <button
                                    className="bg-transparent border-0 text-black float-right"
                                    onClick={() => setShowModal(false)}
                                >
                                    <span className="text-black h-7 w-7 block bg-[#F8F8F9]  rounded-full">
                                    x
                                    </span>
                                </button>
                                </div>
                                <div className="mx-auto"><img src={Logo}/></div>
                                <div className="mx-auto mt-5 text-[#171717] text-[32px] font-[600]">Join waitlist for iOS</div>
                                <div className="relative p-6 flex-auto">
                                <form className="px-8 pt-6 pb-8 w-full">
                                    <label className="block text-[#333333] mb-1">
                                    Name
                                    </label>
                                    <input required onChange={(e) => newInput(e, "name")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="Enter your name" />
                                    <label className="block text-[#333333] mb-1 mt-5">
                                    Email Address
                                    </label>
                                    <input required onChange={(e) => newInput(e, "email")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]" placeholder="example@gmail.com" />
                                    <label className="block text-[#333333] mb-1 mt-5">
                                    Phone Number
                                    </label>
                                    <input required onChange={(e) => newInput(e, "phone_number")} className="shadow-xs text-black appearance-none border focus:outline-none rounded-[8px] w-full py-2 px-1 pl-4 text-[16px]]" placeholder="09088865324"/>
                                    <button
                                    className="mt-9 text-white w-full bg-[#E1693E] active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={submit}
                                >
                                    Submit to Join Waitlist
                                </button>
                                </form>
                                </div>
                            </div>
                            </div>
                        </div>
                    ) : null}
                    </>
                    </div>
    </div>
    )
}

export default FooterOne