import React from "react";
import Header from "../header";
import EliteBody from "./homebody"
import Footer from "../footer/footer1"

const Home = () => {
   return ( 
        <div className="font-Inter">
         <Header></Header>
         <EliteBody></EliteBody>
         <Footer></Footer>
        </div>
   )
}

export default Home