import React,{useState} from 'react'
import man from "../../images/privateman.svg"
import Logo from '../../images/logo.svg'
import axios from 'axios'

function EmailVerification() {

    const [email, setEmail] = useState()
    const [error, setError] = useState()

    function verifyEmail(e){
        e.preventDefault();
        if(!email){
            setError('Please input an email address')
        }else{
            axios.get('https://backend.poraapp.com/api/v1/verify/private/email?email='+email)
            .then((res) => {
                if(res.data.status == 'success'){
                    sessionStorage.setItem('identity',res.data.identity)
                    sessionStorage.setItem('email',email)
                    window.location.href='/verify-otp'
                }else{
                    if(res.data.responseCode){
                        setError(res.data.responseMessage)
                    }else{
                        setError(res.data.message)
                    }
                }
            }).catch((e)=>{
                setError('There was an error, please try again later')
            })
        }
    }

  return (
    <>
        <div className='grid grid-cols-2'>
            <div className='col-span-2 lg:col-span-1 grid grid-cols-1 pr-5'>
                <div className='col-span-1 pt-5 pl-5 lg:pl-20 flex flex-col space-y-10 pr-10'>
                    <img className='w-[215px]' src={Logo} />
                    <div className='flex flex-col space-y-5'>
                        <span className='text-[32px] lg:text-[42px] text-[#131313] font-[600] leading-[36px] leading-[58px] mt-5 lg:mt-20'>Get started with your email address</span>
                        <span className='text-[20px] text-[#333333] font-[400] leading-[150%]'>Enter your email address to get you started on an exciting journey with our personal curated service. </span>
                    </div>
                    <form onSubmit={(e) => verifyEmail(e)} className='flex flex-col space-y-4 lg:w-[70%]'>
                        <label>Email Address</label>
                        <input required onChange={(e) => setEmail(e.target.value)} type='email' placeholder="Enter email address" className='border-[1px] py-4 px-3 border-[#E2E2E2] rounded-[4px]'/>
                        {error ? <span className='text-red-500'>{error}</span> : null}
                        <button type="submit" className='bg-[#E1693E] w-[50%] py-3 text-[20px] text-white font-[700]'>Continue</button>
                    </form>
                </div>
            </div>
            <div className='col-span-1 hidden lg:block'>
                <img className='' src={man} />
            </div>
        </div>
    </>
  )
}

export default EmailVerification