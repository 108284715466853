import React from 'react'
import about from "../../images/about-private.svg"
import about2 from "../../images/about-private2.svg"
import how from "../../images/how.svg"

function homeBody() {
  return (
    <>
        <div className='grid grid-cols-1 px-5 lg:px-8'>
            <div className="col-span-1 lg:mx-10 bg-[url('images/privatehero1.svg')] flex flex-col text-white mb-10 items-center justify-center space-y-9 py-10 lg:py-20 mt-5 lg:mt-10">
                <p className='font-[700] text-[32px] lg:text-[60px] leading-[40px] lg:leading-[77px] text-center'>Welcome to Private Matching</p>
                <p className='text-center font-[400] text-[20px] leading-[29px] w-[84%]'>Pora Private Matching is an exclusive service for Africans in Africa and in the diaspora. We help busy professionals & executives find partners for serious long lasting relationships.</p>
                <a href='/verify-email' className='bg-[#E1693E] rounded-[8px] p-3 px-6'>Sign up for Private Matching</a>
            </div>
        </div>
        <div className='grid grid-cols-1 px-5 lg:px-8'>
            <div className='col-span-1 lg:mt-10 grid grid-cols-2 gap-x-10 lg:mx-10'>
                <div className='col-span-2 lg:col-span-1 flex flex-col my-auto space-y-4'>
                    <p className='text-black text-[32px] lg:text-[45px] font-[700] lg:mb-5'>About Private Matching</p>
                    <div className='lg:hidden col-span-2 lg:col-span-1 '>
                        <img src={about} />
                    </div>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>We created this service for exclusive clients who have less opportunities to meet members of the opposite gender, and busy individuals who value privacy and have more specific set of qualities they desire in a partner. Our typical Private Matching clientele would not want their profile to be on a dating application.</p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>At Pora, we believe that finding a compatible partner – who genuinely matches your values, background and culture is crucial for your lasting happiness. So, if you are serious about finding a partner, and need experienced professionals to do the work for you, then Pora Private Matching is for you.</p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>Pora Private Matching sets you in another league, where you commence the process with a private consultation with our professional team.</p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>Pora Private Matching offers a more discreet service using a smaller elite dating database. We match you on parameters that matter the most to you.</p>
                </div>
                <div className='hidden lg:block col-span-2 lg:col-span-1 '>
                    <img src={about} />
                </div>
            </div>
        </div>
        <div className='grid grid-cols-1 px-5 lg:px-8 mt-5 lg:mt-10'>
            <div className='col-span-1 lg:mt-10 grid grid-cols-2 gap-x-10 lg:mx-10'>
                <div className='hidden lg:block col-span-2 lg:col-span-1 '>
                    <img src={about2} />
                </div>
                <div className='col-span-2 lg:col-span-1 flex flex-col my-auto space-y-4'>
                    <p className='text-black text-[32px] lg:text-[45px] font-[700]'>Hand Selected Matches</p>
                    <div className='lg:hidden col-span-2 lg:col-span-1 '>
                        <img src={about2} />
                    </div>
                    <p className=''></p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>When potential matches are identified, we send you 2-3 short profiles according to your preferences. Your potential matches also receive your short profile. </p>
                    <p className='text-[#4F4F4F] font-[400] text-[18px]'>If there is interest on both sides, you’re given your match’s contact details and voila! Start chatting and getting to know each other. </p>
                    <a href='/verify-email' className='bg-[#E1693E] rounded-[8px] p-3 px-6 text-white lg:w-[50%]'>Sign up for Private Matching</a>
                </div>
            </div>
        </div>
        <div className='grid grid-cols-1 px-8 bg-[#FDF7F5] mt-10 lg:mt-20 mb-10'>
            <div className='col-span-1 mt-10 grid grid-cols-2 gap-x-20 lg:mx-10 mb-10 '>
                <div className='col-span-2 lg:col-span-1 flex flex-col my-auto'>
                    <p className='text-black text-[32px] lg:text-[45px] font-[700]'>How does it work?</p>
                    <div className='lg:hidden col-span-1 '>
                        <img src={how} />
                    </div>
                    <div className='flex flex-col space-y-5 pt-5 lg:pt-auto'>
                        <div className='grid grid-cols-8 lg:space-x-6'>
                            <span className='col-span-2 lg:col-span-1 border-[1px] border-[#333333] rounded-full w-[56px] h-[56px] text-center grid'><p className='my-auto text-[#E1693E] text-[26px] '>1</p></span>
                            <div className='col-span-6 lg:col-span-7'>
                                <p className='text-[#E1693E] text-[22px] lg:text-[24px]'>Apply for Private Matching</p>
                                <p className='mt-2'>Get the Pora App on Google Play Store for Android or Apple Store for iOS.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 lg:space-x-6'>
                            <span className='col-span-2 lg:col-span-1 border-[1px] border-[#333333] rounded-full w-[56px] h-[56px] text-center grid'><p className='my-auto text-[#E1693E] text-[26px] '>2</p></span>
                            <div className='col-span-6 lg:col-span-7'>
                                <p className='text-[#E1693E] text-[22px] lg:text-[24px]'>Specify your preference</p>
                                <p className='mt-2'>Specify your preference for Private Matching. Then we contact you and get to know you extensively. We catalogue your values, background, goals, desires and interests.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 lg:space-x-6'>
                            <span className='col-span-2 lg:col-span-1 border-[1px] border-[#333333] rounded-full w-[56px] h-[56px] text-center grid'><p className='my-auto text-[#E1693E] text-[26px] '>3</p></span>
                            <div className='col-span-6 lg:col-span-7'>
                                <p className='text-[#E1693E] text-[22px] lg:text-[24px]'>Start chatting with matches.</p>
                                <p className='mt-2'>You’ll be assigned a personal matchmaker who’ll be tasked with digging out potential matches from within our selective portfolio of eligible single men and women.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-8 lg:space-x-6'>
                            <span className='col-span-2 lg:col-span-1 border-[1px] border-[#333333] rounded-full w-[56px] h-[56px] text-center grid'><p className='my-auto text-[#E1693E] text-[26px] '>4</p></span>
                            <div className='col-span-6 lg:col-span-7'>
                                <p className='text-[#E1693E] text-[22px] lg:text-[24px]'>Connecting hearts across continents</p>
                                <p className='mt-2'>We are currently matching couples in Canada, United Kingdom, Ireland & USA.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hidden lg:block col-span-1 '>
                    <img src={how} />
                </div>
            </div>
        </div>
    </>
  )
}

export default homeBody