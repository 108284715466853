import React from "react";
import Header from "../header";
import PrivateMatchingBody from "./body"
import Footer from "../footer/footer1"

const PrivateMatching = () => {
   return ( 
        <div className="font-Inter">
         <Header></Header>
         <PrivateMatchingBody></PrivateMatchingBody>
         <Footer></Footer>
        </div>
   )
}

export default PrivateMatching