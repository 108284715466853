import React from 'react'

function Hero() {
  return (
    <>
        <div className="flex flex-col bg-[url('images/privatehero.svg')] w-full pt-10 items-center pb-10 space-y-5">
            <span className='text-[#333333] text-[32px] lg:text-[64px] font-[600] text-center '>Join Private Matching</span>
            <span className='text-[#2C2C2C] font-[28px] text-center w-[65%] pb-8'>Please provide as much detail as possible in your responses. This will help us better understand your needs and match you to the right person. Be rest assured, your information is safe with us. </span>
        </div>
    </>
  )
}

export default Hero